/**
 * @fileOverview This file contains the store for the application. 
 * It combines the reducers from the video and auth slices into one store.
 * The store is then exported for use in the application. 
 * The store is created using the configureStore function from Redux Toolkit. 
 * The store is configured with the reducers from the video and auth slices.
 * 
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import { configureStore } from '@reduxjs/toolkit';
import videoReducer from './video/videoSlice';
import authSlice from './auth/authSlice';


const store = configureStore({
  reducer: {
    auth: authSlice,
    videos: videoReducer,
  }
});

export default store;
