import React, { Suspense, lazy } from "react";
import Layout from "../components/layout/Layout";

// Lazy loading components
const ProjectsSection = lazy(() => import("../components/projects/Projects"));

const ProjectsPage = () => {
    return (
        <Layout>
            <Suspense fallback={<div>Loading Projects Page...</div>}>
                <ProjectsSection />
            </Suspense>
        </Layout>
    );
};

export default ProjectsPage;

