/**
 * @fileOverview This file contains the admin page for admin to manage the website. It contains tabs for different functionalities.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React from "react";

import Layout from "../../components/layout/Layout";
import AdminTabs from "../../components/admin/tabs/AdminTabs";
const AdminPage = () => {


    return (
        <Layout>
            <div className="flex flex-col items-center justify-center w-full min-h-screen ">
                <AdminTabs />
            </div>
        </Layout>
    );
}

export default AdminPage;