
import React, { useState, useEffect } from "react";
import Card from "./tab/Card";
import Pagination from "../Pagination";
import SearchBar from "./tab/SearchBar";
import Dropdown from "./tab/Dropdown";
import NewItem from "./tab/NewItem";
import ItemFormModal from "./ItemFormModal";

const ProjectsTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [5, 10, 15, 20];
  const sortOptions = ["Recommended", "Sort by A-Z", "Sort by Z-A", "Newest"];
  const dateOptions = ["All Time", "Yesterday", "Last Week", "This Month", "This Year", "Another Period"];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [dateOption, setDateOption] = useState(dateOptions[0]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    const mockData = [
      {
        id: 1,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'React Project Management',
        author: 'Alice Johnson',
        link: 'https://www.reactproject.com',
        description: 'A project management tool built with React.'
      },
      {
        id: 2,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Node.js Backend API',
        author: 'Bob Lee',
        link: 'https://www.nodejsapi.com',
        description: 'A backend API built with Node.js.'
      }
    ];
    setData(mockData);
    setFilteredData(mockData);
    setLoading(false);
  }, []);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleHide = (id) => {
    console.log(`Hide item with id: ${id}`);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setFormModalOpen(true);
  };

  const handleDelete = (id) => {
    console.log(`Delete item with id: ${id}`);
  };

  const handleSearch = (term) => {
    setCurrentPage(1);
    if (term === '') {
      setFilteredData(data);
    } else {
      const lowercasedTerm = term.toLowerCase();
      const filtered = data.filter(item =>
        item.title.toLowerCase().includes(lowercasedTerm) ||
        item.author.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredData(filtered);
    }
  };

  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (SelectValue) => {
    console.log(`Items per page: ${SelectValue}`);
    setItemsPerPage(parseInt(SelectValue, 10));
    setCurrentPage(1);
  };

  const handleFormSubmit = (values, { setSubmitting, setStatus }) => {
    console.log('Updated item:', values);
    setFormModalOpen(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, filteredData]);

  return (
    <div>
      <div className="flex items-center justify-between mx-4">
        <NewItem />
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="flex items-center justify-around space-x-4 mb-4">
        <Dropdown
          label="Date"
          options={dateOptions}
          selectedOption={dateOption}
          onSelect={setDateOption}
        />
        <Dropdown
          label="Sort By"
          options={sortOptions}
          selectedOption={sortOption}
          onSelect={setSortOption}
        />
        <Dropdown
          label="Show"
          options={itemsPerPageOptions}
          onSelect={handleItemsPerPageChange}
        />
      </div>
      <div className="p-4 m-4 grid grid-cols-1 gap-4">
        {loading ? (
          <div>Loading...</div>
        ) : (
          currentData.map(item => (
            <Card
              key={item.id}
              thumbnail={item.thumbnail}
              title={item.title}
              link={item.link}
              description={item.description}
              onHide={() => handleHide(item.id)}
              onEdit={() => handleEdit(item)}
              onDelete={() => handleDelete(item.id)}
            />
          ))
        )}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      {isFormModalOpen && (
        <ItemFormModal
          isOpen={isFormModalOpen}
          onRequestClose={() => setFormModalOpen(false)}
          initialValues={currentItem}
          onSubmit={handleFormSubmit}
          isEditing={true}
          formType="Projects"
        />
      )}
    </div>
  );
};

export default ProjectsTab;
