/**
 * @fileOverview This file contains the auth slice for the application.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginApi, registerApi, logoutApi, requestPasswordResetApi, resetPasswordApi, changePasswordApi } from './../../api/authService';

export const loginUser = createAsyncThunk(
    'auth/login',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await loginApi(credentials);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await registerApi(userData);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            await logoutApi();
            return;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async (email, { rejectWithValue }) => {
        try {
            await requestPasswordResetApi(email);
            return;
        } catch (error) {
            return rejectWithValue(error.response.data || "An error occurred");
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ token, newPassword }, { rejectWithValue }) => {
        try {
            await resetPasswordApi(token, newPassword);
            return;
        } catch (error) {
            return rejectWithValue(error.response.data || "An error occurred");
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async ({ currentPassword, newPassword }, { rejectWithValue }) => {
        try {
            await changePasswordApi(currentPassword, newPassword);
            return;
        } catch (error) {
            return rejectWithValue(error.response.data || "An error occurred");
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false,
        isLoading: false,
        error: null,
        activeView: 'default'
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        resetAuthState: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(loginUser.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(loginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        .addCase(loginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(registerUser.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(registerUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        .addCase(registerUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(logoutUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(logoutUser.fulfilled, (state) => {
            state.user = null;
            state.isLoading = false;
            state.isAuthenticated = false;
        })
        .addCase(logoutUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(requestPasswordReset.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(requestPasswordReset.fulfilled, (state) => {
            state.isLoading = false;
        })
        .addCase(requestPasswordReset.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(resetPassword.fulfilled, (state) => {
            state.isLoading = false;
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase(changePassword.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(changePassword.fulfilled, (state) => {
            state.isLoading = false;
        })
        .addCase(changePassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    }
});

export const { clearError, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
