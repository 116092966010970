/**
 * @fileOverview This file contains general component Pagination.
 * @author Lisi Cao
 * @version 1.0.4
 * @company Iter Innovandi.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import AccessibleInput from './AccessibleInput';
import BaseButton from './BaseButton';

// Custom button component with accessibility enhancements
const CustomButton = ({ onPress, disabled = false, ariaLabel, children }) => {
  return (
    <BaseButton
      onPress={onPress}
      disabled={disabled}
      aria-label={ariaLabel}
      className='p-2 bg-gray-300 hover:bg-gray-400 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-300 ease-in-out
      dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-300 dark:focus:ring-opacity-50 dark:text-gray-300 dark:hover:text-gray-200 dark:focus:text-gray-200'
    >
      {children}
    </BaseButton>
  );
};

CustomButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Pagination component to navigate between pages
const Pagination = ({ currentPage = 1, totalPages = 1, onPageChange }) => {
  // Handler for first page navigation
  const handleFirstPage = () => {
    if (currentPage > 1) {
      onPageChange(1);
    }
  };

  // Handler for last page navigation
  const handleLastPage = () => {
    if (currentPage < totalPages) {
      onPageChange(totalPages);
    }
  };

  // Handler for previous page navigation
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  // Handler for next page navigation
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // Handler for direct page input change
  const handleInputChange = (e) => {
    if (e && e.target) {
      const { value } = e.target;
      let page = parseInt(value, 10);
      if (!isNaN(page) && page >= 1 && page <= totalPages) {
        onPageChange(page);
      } else if (value === '') {
        onPageChange(1);
      }
    }
  };

  // Render pagination controls
  return (
    <div className='flex justify-center items-center space-x-2 my-4'>
      {/* First page button */}
      <CustomButton
        onPress={handleFirstPage}
        isDisabled={currentPage === 1}
        ariaLabel="Go to first page"
      >
        <FaAngleDoubleLeft />
      </CustomButton>
      {/* Previous page button */}
      <CustomButton
        onPress={handlePrevious}
        isDisabled={currentPage === 1}
        ariaLabel="Go to previous page"
      >
        <FaChevronLeft />
      </CustomButton>
      {/* Current page input */}
      <p>Pg </p>
      <div className='container w-12 text-sm'>
        <AccessibleInput
          value={currentPage.toString()}
          onChange={handleInputChange}
          type="number"
          name="currentPage"
          min="1"
          max={totalPages.toString()}
          className='text-center'
          aria-label="Page number"
        />
      </div>
      <span className="px-2">{`of ${totalPages}`}</span>
      {/* Next page button */}
      <CustomButton
        onPress={handleNext}
        isDisabled={currentPage === totalPages}
        ariaLabel="Go to next page"
      >
        <FaChevronRight />
      </CustomButton>
      {/* Last page button */}
      <CustomButton
        onPress={handleLastPage}
        isDisabled={currentPage === totalPages}
        ariaLabel="Go to last page"
      >
        <FaAngleDoubleRight />
      </CustomButton>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
