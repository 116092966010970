/**
 * @fileoverview This component is a modal that displays a form for adding or editing an item. This component is used in the AdminTabs component.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AccessibleInput from './../AccessibleInput';
import AccessibleButton from './../AccessibleButton';
import ErrorDisplay from './../ErrorDisplay';
import FileUploader from './../../upload/FileUploader';
import Modal from 'react-modal';

// Sets the app element for accessibility reasons
Modal.setAppElement('#root');

const ItemFormModal = ({ initialValues, onSubmit, isEditing, isOpen, onRequestClose, formType }) => {
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        link: Yup.string().url('Invalid URL').required('Link is required'),
        ...(formType === 'Books' && { author: Yup.string().required('Author is required') })
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={isEditing ? 'Edit Item' : 'New Item'}
            className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
        >
            <div className="bg-white dark:bg-gray-600 dark:text-gray-300 p-6 rounded-lg shadow-md max-w-md w-full">
                <h2 className="text-2xl font-bold mb-4">{isEditing ? 'Edit Item' : 'New Item'}</h2>
                <div className="mb-6">
                    <FileUploader />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, status }) => (
                        <Form>
                            {/* Title input field with validation feedback */}
                            <div className="mb-6">
                                <Field name="title">
                                    {({ field, meta }) => (
                                        <div className="flex flex-col space-y-2">
                                            <AccessibleInput
                                                {...field}
                                                label="Title"
                                                showLabel={true}
                                                placeholder="Enter the title"
                                            />
                                            {meta.touched && meta.error && <ErrorDisplay message={meta.error} />}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* Conditional author input for book items */}
                            {formType === 'Books' && (
                                <div className="mb-6">
                                    <Field name="author">
                                        {({ field, meta }) => (
                                            <div className="flex flex-col space-y-2">
                                                <AccessibleInput
                                                    {...field}
                                                    label="Author"
                                                    showLabel={true}
                                                    placeholder="Enter the author"
                                                />
                                                {meta.touched && meta.error && <ErrorDisplay message={meta.error} />}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                            )}
                            {/* Description input field */}
                            <div className="mb-6">
                                <Field name="description">
                                    {({ field, meta }) => (
                                        <div className="flex flex-col space-y-2">
                                            <AccessibleInput
                                                {...field}
                                                label="Description"
                                                showLabel={true}
                                                placeholder="Enter the description"
                                            />
                                            {meta.touched && meta.error && <ErrorDisplay message={meta.error} />}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* Link input field with validation */}
                            <div className="mb-6">
                                <Field name="link">
                                    {({ field, meta }) => (
                                        <div className="flex flex-col space-y-2">
                                            <AccessibleInput
                                                {...field}
                                                label="Link"
                                                showLabel={true}
                                                placeholder="Enter the link"
                                            />
                                            {meta.touched && meta.error && <ErrorDisplay message={meta.error} />}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* Form action buttons */}
                            <div className="flex justify-center space-x-4">
                                <AccessibleButton
                                    label={isSubmitting ? 'Saving...' : 'Save Changes'}
                                    type="submit"
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting}
                                />
                                <AccessibleButton
                                    label="Cancel"
                                    type="button"
                                    onPress={onRequestClose}
                                />
                            </div>
                            {status && status.error && <ErrorDisplay message={status.error} />}
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default ItemFormModal;
