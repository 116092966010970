/**
 * @fileOverview This file contains the rename file component for upload component use cases.
 * @author Lisi Cao
 * @see {@link https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.0.0
 * @company Iter Innovandi.
 */


import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RenameFile = ({ renamingFile, onRename, onCancel }) => {
  const { t } = useTranslation();
  const [newFileName, setNewFileName] = useState(renamingFile ? renamingFile.name : '');

  const messages = {
    confirm: t('upload.confirm'),
    cancel: t('upload.cancel')
  };

  const handleRename = () => {
    onRename(renamingFile, newFileName);
    onCancel();
  };

  return (
    renamingFile && (
      <div className="flex items-center mt-2">
        <input
          type="text"
          value={newFileName}
          onChange={(e) => setNewFileName(e.target.value)}
          className="border border-gray-300 rounded p-2 mr-2"
        />
        <button onClick={handleRename} className="px-4 py-2 bg-blue-600 text-white rounded">
          {messages.confirm}
        </button>
        <button onClick={onCancel} className="ml-2 px-4 py-2 bg-gray-600 text-white rounded">
          {messages.cancel}
        </button>
      </div>
    )
  );
};

RenameFile.propTypes = {
  renamingFile: PropTypes.object,
  onRename: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RenameFile;
