/**
 * @fileoverview This component is a tabbed interface that allows the user to switch between different content sections.
 * @author Lisi Cao
 * @see {@link https://react-spectrum.adobe.com/react-aria/examples/swipeable-tabs.html}
 * @version 1.0.4
 * @company Iter Innovandi.
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Collection, Tab, TabList, TabPanel, Tabs } from 'react-aria-components';
import { animate, motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import BooksTab from './BooksTab';
import VideosTab from './VideosTab';
import ProjectsTab from './ProjectsTab';

let tabs = [
  { id: 'books', label: 'Books' },
  { id: 'videos', label: 'Videos' },
  { id: 'projects', label: 'Projects' },
];

const AdminTabs = () => {
  let [selectedKey, setSelectedKey] = useState(tabs[0].id);

  let tabListRef = useRef(null);
  let tabPanelsRef = useRef(null);

  let { scrollXProgress } = useScroll({
    container: tabPanelsRef
  });

  let [tabElements, setTabElements] = useState([]);
  useEffect(() => {
    if (tabElements.length === 0) {
      let tabs = tabListRef.current.querySelectorAll('[role=tab]');
      setTabElements(tabs);
    }
  }, [tabElements]);

  let getIndex = useCallback(
    (x) => Math.max(0, Math.floor((tabElements.length - 1) * x)),
    [tabElements]
  );

  let transform = (x, property) => {
    if (!tabElements.length) return 0;

    let index = getIndex(x);
    let difference = index < tabElements.length - 1
      ? tabElements[index + 1][property] - tabElements[index][property]
      : tabElements[index].offsetWidth;
    let percent = (tabElements.length - 1) * x - index;
    let value = tabElements[index][property] + difference * percent;

    return value || 0.1;
  };

  let x = useTransform(scrollXProgress, (x) => transform(x, 'offsetLeft'));
  let width = useTransform(scrollXProgress, (x) => transform(x, 'offsetWidth'));

  useMotionValueEvent(scrollXProgress, 'change', (x) => {
    if (animationRef.current || !tabElements.length) return;
    setSelectedKey(tabs[getIndex(x)].id);
  });

  let animationRef = useRef(null);
  let onSelectionChange = (selectedKey) => {
    setSelectedKey(selectedKey);

    if (scrollXProgress.getVelocity() && !animationRef.current) {
      return;
    }

    let tabPanel = tabPanelsRef.current;
    let index = tabs.findIndex((tab) => tab.id === selectedKey);
    animationRef.current?.stop();
    animationRef.current = animate(
      tabPanel.scrollLeft,
      tabPanel.scrollWidth * (index / tabs.length),
      {
        type: 'spring',
        bounce: 0.2,
        duration: 0.6,
        onUpdate: (v) => {
          tabPanel.scrollLeft = v;
        },
        onPlay: () => {
          tabPanel.style.scrollSnapType = 'none';
        },
        onComplete: () => {
          tabPanel.style.scrollSnapType = '';
          animationRef.current = null;

          const currentTabPanel = tabPanel.children[index];
          if (currentTabPanel) {
            currentTabPanel.scrollTop = 0;
          }
        }
      }
    );
  };

  return (
    <Tabs
      className="container w-full min-h-screen flex flex-col items-center"
      selectedKey={selectedKey}
      onSelectionChange={onSelectionChange}
    >
      <div className="relative w-full max-w-4xl">
        <TabList ref={tabListRef} className="flex justify-center -mx-1" items={tabs}>
          {(tab) => (
            <Tab className="cursor-pointer px-3 py-1.5 text-md transition outline-none touch-none text-center">
              {({ isSelected, isFocusVisible }) => (
                <>
                  {tab.label}
                  {isFocusVisible && isSelected && (
                    <motion.span
                      className="absolute inset-0 z-10 rounded-full ring-2 ring-black ring-offset-2"
                      style={{ x, width }}
                    />
                  )}
                </>
              )}
            </Tab>
          )}
        </TabList>
        <motion.span
          className="absolute inset-0 z-0 bg-white rounded-full mix-blend-difference"
          style={{ x, width }}
        />
      </div>
      <div
        ref={tabPanelsRef}
        className="my-4 overflow-hidden h-full snap-x snap-mandatory no-scrollbar flex w-full max-w-4xl mx-auto bg-white dark:bg-dark-customGray"
      >
        <Collection items={tabs}>
          {(tab) => (
            <TabPanel
              shouldForceMount
              className="flex-shrink-0 w-full h-full px-2 box-border snap-start outline-none -outline-offset-2 rounded focus-visible:outline-black"
            >
              {tab.id === 'books' && <BooksTab />}
              {tab.id === 'videos' && <VideosTab />}
              {tab.id === 'projects' && <ProjectsTab />}
            </TabPanel>
          )}
        </Collection>
      </div>
    </Tabs>
  );
}

export default AdminTabs;
