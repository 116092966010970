/**
 * @fileOverview This file contains the custom modal component for common use cases.
 * @author Lisi Cao
 * @see {@link https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.1.0
 * @company Iter Innovandi.
 */

import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const CustomModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  const { t } = useTranslation();

  const messages = {
    close: t('upload.close')
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
    >
      <div className="bg-white p-4 rounded-lg max-w-3xl w-full max-h-full overflow-auto">
        {children || <div>No content available</div>}
        <button onClick={onRequestClose} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">
          {messages.close}
        </button>
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomModal;
