/**
 * @fileOverview This file contains the video service for the application.
 * @author Lisi Cao
 * @version 2.0.0
 * @company Iter Innovandi.
 */

import apiClient from './apiMock';

// Fetch all videos
export const getVideosApi = async () => {
  const response = await apiClient.get('/videos');
  return response.data.data; 
};

// Add a new video
export const addVideoApi = async (video) => {
  const response = await apiClient.post('/videos', video);
  return response.data.data;
};

// Update an existing video
export const updateVideoApi = async (id, video) => {
      const url = `/videos/${id}`;
      const response = await apiClient.put(url, video);
      return response.data.data;
  };
  
// Delete a video
export const deleteVideoApi = async (id) => {
  const response = await apiClient.delete(`/videos/${id}`);
  return response.data.data; 
};
