import React, { lazy, useState, Suspense } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../components/layout/Layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoadingPlaceholder = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="w-10 h-10 bg-blue-500 rounded-full animate-bounce"></div>
  </div>
);

const components = [
  { Component: lazy(() => import('../components/landing/SMEs')), key: 'SMEs' },
  { Component: lazy(() => import('../components/landing/AboutPage')), key: 'AboutPage' },
  { Component: lazy(() => import('../components/landing/case-studies/CaseStudiesPage')), key: 'CaseStudy' },
  { Component: lazy(() => import('../components/landing/service/ServiceYoutubeVideo')), key: 'ServiceYoutubeVideo' },
  { Component: lazy(() => import("../components/landing/map/MapComponent")), key: 'MapComponent' }
];

const LandingPage = () => {
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

  const loadNextComponent = () => {
    if (currentComponentIndex < components.length - 1) {
      setCurrentComponentIndex(currentComponentIndex + 1);
    }
  };

  return (
    <Layout>
    <div className="">
      {/* Used react-toastify to notify user */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={localStorage.getItem("theme")}
      />
      <AnimatePresence>
        {components.slice(0, currentComponentIndex + 1).map(({ Component, key }, index) => (
          <Suspense key={key} fallback={<LoadingPlaceholder />}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onAnimationComplete={() => {
                if (index === currentComponentIndex) {
                  loadNextComponent();
                }
              }}
            >
              <Component />
            </motion.div>
          </Suspense>
        ))}
      </AnimatePresence>
    </div>
    </Layout>
  );
};

export default LandingPage;
