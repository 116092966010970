/**
 * @fileOverview This file contains an accessible input for admin.
 * @author Lisi Cao
 * @version 1.0.3
 * @company Iter Innovandi.
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTextField } from '@react-aria/textfield';
import { mergeProps } from '@react-aria/utils';

const AccessibleInput = ({
    icon = null,
    label,
    showLabel = true,
    placeholder,
    onFocus = () => { },
    onBlur = () => { },
    onChange,
    value,
    type = "text",
    name,
    min,
    max,
    className = "",
    ...props
}) => {
    const ref = useRef();
    const { labelProps, inputProps } = useTextField(
        {
            label,
            placeholder,
            onFocus,
            onBlur,
            onChange,
            value,
            type,
            name,
            min,
            max,
            ...props
        },
        ref
    );

    const mergedProps = mergeProps(inputProps, { onChange, onBlur, onFocus });

    return (
        <div className="relative w-full">
            {showLabel && label && (
                <label {...labelProps} className="block font-semibold mb-1 text-gray-700 dark:text-gray-300">
                    {label}
                </label>
            )}
            <div className="flex items-center overflow-hidden rounded-full 
            border border-gray-900 bg-transparent focus-within:border-blue-500 
            dark:border-gray-300 dark:focus-within:border-blue-300"
            >
                {icon && (
                    <span className="pl-3 text-gray-500 dark:text-gray-400">
                        {icon}
                    </span>
                )}
                <input
                    {...mergedProps}
                    ref={ref}
                    className={`
                        w-full p-3 bg-transparent outline-none text-gray-900 placeholder-gray-500 focus:placeholder-transparent 
                        dark:text-gray-100 dark:placeholder-gray-400 dark:focus:placeholder-transparent 
                        ${className}`
                    }
                    aria-label={label}
                    value={value}
                    name={name}
                    min={min}
                    max={max}
                />
            </div>
        </div>
    );
};

AccessibleInput.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AccessibleInput;