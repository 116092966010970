/**
 * @fileoverview This component is a tab that displays a list of books with the ability to hide, edit, and delete each item. 
 * It also includes pagination to navigate through the list of books. This component is used in the AdminTabs component 
 * to display the Books tab content. This component is a child component of the AdminTabs component. This component uses 
 * the Card component to display each book item. This component uses the Pagination component to navigate through the list 
 * of books.
 * @author Lisi Cao
 * @version 1.2.0
 * @company Iter Innovandi.
 */

import React, { useState, useEffect } from "react";
import Card from "./tab/Card";
import Pagination from "../Pagination";
import SearchBar from "./tab/SearchBar";
import Dropdown from "./tab/Dropdown";
import NewItem from "./tab/NewItem";
import ItemFormModal from "./ItemFormModal";

const BooksTab = () => {
  // State for pagination and data management
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [5, 10, 15, 20];
  const sortOptions = ["Recommended", "Sort by A-Z", "Sort by Z-A", "Newest"];
  const dateOptions = ["All Time", "Yesterday", "Last Week", "This Month", "This Year", "Another Period"];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [dateOption, setDateOption] = useState(dateOptions[0]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  // Fetch and set initial mock data
  useEffect(() => {
    const mockData = [
      {
        id: 1,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Empowering Your Business with AI',
        author: 'Isaiah Jones',
        link: 'https://www.insertlink.com',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 2,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Understanding Machine Learning',
        author: 'Jane Doe',
        link: 'https://www.machinelearning.com',
        description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 3,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Introduction to Deep Learning',
        author: 'John Smith',
        link: 'https://www.deeplearning.com',
        description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
      },
      {
        id: 4,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Advanced Neural Networks',
        author: 'Emily White',
        link: 'https://www.advancednn.com',
        description: 'Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.'
      },
      {
        id: 5,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Data Science for Business',
        author: 'Michael Brown',
        link: 'https://www.datascience.com',
        description: 'Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.'
      },
      {
        id: 6,
        thumbnail: 'https://via.placeholder.com/150',
        title: 'Big Data Analytics',
        author: 'Sarah Green',
        link: 'https://www.biganalytics.com',
        description: 'Maecenas faucibus mollis interdum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.'
      }
    ];
    setData(mockData);
    setFilteredData(mockData);
    setLoading(false);
  }, []);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handlers for pagination, search, and modal management
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleHide = (id) => {
    console.log(`Hide item with id: ${id}`);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setFormModalOpen(true);
  };

  const handleDelete = (id) => {
    console.log(`Delete item with id: ${id}`);
  };

  const handleSearch = (term) => {
    setCurrentPage(1);
    if (term === '') {
      setFilteredData(data);
    } else {
      const lowercasedTerm = term.toLowerCase();
      const filtered = data.filter(item =>
        item.title.toLowerCase().includes(lowercasedTerm) ||
        item.author.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredData(filtered);
    }
  };

  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (SelectValue) => {
    setItemsPerPage(parseInt(SelectValue, 10));
    setCurrentPage(1);
  };

  const handleFormSubmit = (values, { setSubmitting, setStatus }) => {
    console.log('Updated item:', values);
    setFormModalOpen(false);
  };

  // Reset current page on filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, filteredData]);

  return (
    <div>
      {/* Top bar with the new item button and the search bar */}
      <div className="flex items-center justify-between mx-4">
        <NewItem />
        <SearchBar onSearch={handleSearch} />
      </div>

      {/* Dropdown filters for date, sorting, and items per page */}
      <div className="flex items-center justify-around space-x-4 mb-4">
        <Dropdown
          label="Date"
          options={dateOptions}
          selectedOption={dateOption}
          onSelect={setDateOption}
        />
        <Dropdown
          label="Sort By"
          options={sortOptions}
          selectedOption={sortOption}
          onSelect={setSortOption}
        />
        <Dropdown
          label="Show"
          options={itemsPerPageOptions}
          onSelect={handleItemsPerPageChange}
        />
      </div>

      {/* Main content area displaying cards for each book */}
      <div className="p-4 m-4 grid grid-cols-1 gap-4">
        {loading ? (
          <div>Loading...</div>  // Loading indicator before data is available
        ) : (
          currentData.map(item => (
            <Card
              key={item.id}
              thumbnail={item.thumbnail}
              title={item.title}
              author={item.author}
              link={item.link}
              description={item.description}
              onHide={() => handleHide(item.id)}
              onEdit={() => handleEdit(item)}
              onDelete={() => handleDelete(item.id)}
            />
          ))
        )}
      </div>

      {/* Pagination controls at the bottom of the page */}
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

      {/* Modal for editing a book item, shown when a book is selected for editing */}
      {isFormModalOpen && (
        <ItemFormModal
          isOpen={isFormModalOpen}
          onRequestClose={() => setFormModalOpen(false)}
          initialValues={currentItem}
          onSubmit={handleFormSubmit}
          isEditing={true}
          formType="Books"
        />
      )}
    </div>
  );

};

export default BooksTab;
