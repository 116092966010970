/**
 * @fileOverview This file is for mocking the API requests in the application.
 * @author Lisi Cao
 * @version 2.2.0
 * @company Iter Innovandi.
 */

import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { errorCodeMapping } from "./errorCodeMapping";

const apiClient = axios.create({
  baseURL: "http://localhost:5001",
  headers: {
    "Content-Type": "application/json",
  },
});

//
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { code, errorMessage } = error.response.data;
      const message = errorCodeMapping[code] || errorMessage || error.message;
      if (code === 4003) {
        try {
          const originalRequest = error.config;
          const refreshToken = localStorage.getItem("refreshToken");
          const response = await apiClient.post("/auth/refresh", {
            refreshToken,
          });
          const { token, refreshToken: newRefreshToken } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", newRefreshToken);
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          return apiClient(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/auth/login";
        }
      } else if (code === 4004) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/auth/login";
      } else if (code === 4002) {
        window.location.href = "/unauthorized";
      }
      return Promise.reject(new Error(message));
    }
    return Promise.reject(error);
  }
);

const mock = new MockAdapter(apiClient, { delayResponse: 500 });

// Mock login API
mock.onPost("/auth/login").reply((config) => {
  const { username, password } = JSON.parse(config.data);
  if (username === "admin" && password === "password") {
    return [
      200,
      {
        code: "2000",
        data: { token: "fake-token", refreshToken: "fake-refresh-token" },
        errorMessage: null,
      },
    ];
  } else {
    return [
      401,
      { code: "4000", data: null, errorMessage: errorCodeMapping["4000"] },
    ];
  }
});

// Mock subscribe API
mock.onPost("/subscribe").reply((config) => {
  const { email } = JSON.parse(config.data);
  if (email) {
    return [
      200,
      { code: "2000", data: { email }, message: "Subscription successful" },
    ];
  } else {
    return [
      400,
      { code: "4001", data: null, errorMessage: "Email is required" },
    ];
  }
});

// Mock video APIs
const mockVideos = [
  {
    id: 1,
    thumbnail: "https://via.placeholder.com/150",
    title: "Learning React",
    author: "John Doe",
    link: "https://www.reactvideo.com",
    description: "An introductory video on learning React framework.",
  },
  {
    id: 2,
    thumbnail: "https://via.placeholder.com/150",
    title: "Advanced Redux",
    author: "Jane Smith",
    link: "https://www.reduxvideo.com",
    description: "An advanced video on using Redux for state management.",
  },
];

mock
  .onGet("/videos")
  .reply(200, { code: "2000", data: mockVideos, errorMessage: null });

mock.onPost("/videos").reply((config) => {
  const newVideo = JSON.parse(config.data);
  newVideo.id = mockVideos.length + 1;
  mockVideos.push(newVideo);
  return [200, { code: "2000", data: newVideo, errorMessage: null }];
});

mock.onPut(/\/videos\/\d+/).reply((config) => {
  const match = config.url.match(/\/videos\/(\d+)/);
  const id = match ? match[1] : null;
  if (!id) {
    return [
      404,
      { code: "4002", data: null, errorMessage: errorCodeMapping["4002"] },
    ];
  }
  const updatedVideo = JSON.parse(config.data);
  const index = mockVideos.findIndex((video) => video.id === parseInt(id, 10));
  if (index !== -1) {
    mockVideos[index] = { ...mockVideos[index], ...updatedVideo };
    return [200, { code: "2000", data: mockVideos[index], errorMessage: null }];
  } else {
    return [
      404,
      { code: "4002", data: null, errorMessage: errorCodeMapping["4002"] },
    ];
  }
});

mock.onDelete(/\/videos\/\d+/).reply((config) => {
  const match = config.url.match(/\/videos\/(\d+)/);
  const id = match ? match[1] : null;
  if (!id) {
    return [
      404,
      { code: "4002", data: null, errorMessage: errorCodeMapping["4002"] },
    ];
  }
  const index = mockVideos.findIndex((video) => video.id === parseInt(id, 10));
  if (index !== -1) {
    const deletedVideo = mockVideos.splice(index, 1)[0];
    return [200, { code: "2000", data: deletedVideo, errorMessage: null }];
  } else {
    return [
      404,
      { code: "4002", data: null, errorMessage: errorCodeMapping["4002"] },
    ];
  }
});

export default apiClient;
