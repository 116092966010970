import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="relative flex flex-col text-gray-700 dark:text-white bg-light-gradient dark:bg-dark-gradient min-h-screen"> 
      <Header /> 
        <main className="flex-grow">  
            {children}
        </main>
      <Footer />  
    </div>
  );
};

export default Layout;
