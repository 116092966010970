import React, { Suspense, lazy } from 'react';
import Layout from '../components/layout/Layout';

const VideoList = lazy(() => import("../components/videos/VideoList"));

const VideosPage = () => {
  return (
      <Layout>
      <div className="absolute bottom-0 left-0 w-full h-full z-0 bg-no-repeat bg-cover"
        style={{ backgroundImage: `url('/images/bg-1.png')` }}></div>
      <div className="absolute top-0 left-0 w-full h-full bg-light-gradient dark:bg-dark-gradient opacity-75 z-10"></div>
      <div className="relative z-20">
        <Suspense fallback={<div>Loading Projects Page...</div>}>
            <VideoList />
        </Suspense>
      </div>
      </Layout>
  );
}

export default VideosPage;