/**
 * @fileoverview This component contains the ProtectedRoute component that is used to protect admin route in the application.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useSelector(state => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default ProtectedRoute;