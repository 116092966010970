import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomButton = ({ label, onClick, className='', noBackGround = false, blackBorder = true }) => {
  const { t } = useTranslation();

  return (
    <>
    { !noBackGround ? 
    <button 
      className={`${className} inline-flex items-center justify-center rounded-full mx-auto gradient-border-btn dark:bg-gray-900 text-black dark:text-white font-poppins text-shadow-default 
        p-2 w-40 text-lg sm:p-3 sm:text-xl sm:w-60 md:p-3 md:text-xl md:w-60 lg:text-2xl lg:w-60 xl:p-5 xl:text-3xl xl:w-80`}
        onClick={onClick}
    >
      <span>{t(label)}</span>
    </button>
    : (
      blackBorder ?
    <button 
      className={`inline-flex items-center justify-center rounded-full mx-auto border-8 border-black dark:bg-gray-900 text-black dark:text-white font-poppins text-shadow-default 
        p-2 w-40 text-lg sm:p-3 sm:text-xl sm:w-60 md:p-3 md:text-xl md:w-60 lg:text-2xl lg:w-60 xl:p-5 xl:text-3xl xl:w-80 ${className}`}
        onClick={onClick}
    >
      <span>{t(label)}</span>
    </button>
    : 
    <button 
      className={`inline-flex text-black dark:text-white font-poppins text-shadow-default 
        w-40 text-lg sm:text-xl sm:w-60 md:text-xl md:w-40 lg:text-2xl lg:w-60 xl:text-3xl xl:w-80 ${className}`}
        onClick={onClick}
    >
      <span>{t(label)}</span>
    </button>
    )
  }
  </>);
};

export default CustomButton;

