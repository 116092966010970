/**
 * @fileOverview This file contains an error display for admin.
 * @author Lisi Cao
 * @version 1.0.1
 * @company Iter Innovandi.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '../icons/ErrorIcon';

const ErrorDisplay = ({ message }) => {
    return (
        <div className="flex items-center p-2 rounded bg-red-100 text-red-700 dark:bg-red-200 dark:text-red-900">
            <ErrorIcon />
            <span>{message}</span>
        </div>
    );
};

ErrorDisplay.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorDisplay;
