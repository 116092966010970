/**
 * @fileOverview This file contains layout component Header.
 * @author Lisi Cao
 * @version 2.0.1
 * @company Iter Innovandi.
 */

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import ThemeToggle from "../ThemeToggle";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTheme } from "../../context/ThemeContext";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme(); 

  // Ensure light mode
  useEffect(() => {
    if (theme !== "light") {
      setTheme("light"); 
    }
  }, [theme, setTheme]);


  const menuItems = [
    { key: "about", label: t("header.about") },
    // { key: "books-page", label: t("header.books") },
    { key: "service", label: t("header.service") },
    { key: "videos-page", label: t("header.videos") },
    { key: "projects-page", label: t("header.projects") },
    { key: "partners-page", label: t("header.partners") },
    { key: "book", label: t("header.book"), isButton: true },
  ];

  // Variants for the dropdown animation
  const dropdownVariants = {
    initial: {
      opacity: 0,
      transition: { staggerChildren: 0.1, staggerDirection: -1 }
    },
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.1, duration: 0.5 }
    },
    exit: {
      opacity: 0,
      transition: { staggerChildren: 0.1, staggerDirection: -1 }
    }
  };

  // Variants for the individual menu items animation
  const itemVariants = {
    initial: { y: 20, opacity: 0 },
    open: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 }
  };

  const nodeRef = useRef();

  useEffect(() => {
    if (!isMenuOpen) return;

    // Close the menu if clicking outside of it
    const handleClickOutside = (event) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const renderMenuItems = (className) => (
    <motion.div
      className={className}
      initial="initial"
      animate="open"
      exit="exit"
      variants={dropdownVariants}
    >
      {menuItems.map(item => item.isButton ? (
        <motion.button
          key={item.key}
          variants={itemVariants}
          className="bg-blue-300 text-black font-semibold rounded-full px-4 py-2 w-36 h-10 overflow-hidden text-ellipsis whitespace-nowrap  hover:bg-blue-500 hover:text-white hover:shadow-md mx-auto ">
          <Link to={`/#${item.key}`}>
            {item.label}
          </Link>
        </motion.button>
       ) : (
        <motion.div key={item.key} variants={itemVariants}>
          <Link to={item.key==="about" || item.key === "book" || item.key === "service"? `/#${item.key}` : `/${item.key}`} 
          className="relative block text-center text-black hover:text-gray-800 font-semibold px-4 py-2 w-30 h-10 overflow-hidden text-ellipsis whitespace-nowrap transition-all duration-300 ease-in-out group">
            {item.label}
            {/* Animated underline effect */}
            <span className="absolute bottom-0 left-0 w-0 h-2 bg-blue-500 transition-all duration-300 ease-out group-hover:w-full bg-opacity-50 rounded-xl"></span>
          </Link> 
        </motion.div>
      ))}
    </motion.div>
  );

  return (
    <div className="top-0 z-50 w-full bg-gradient-to-b from-white dark:from-gray-300 to-transparent">
      <div className="relative p-6 flex justify-between items-center">
        <Link to="/" className="flex-none w-32 h-12">
          <img src="/images/logo.png" alt="logo" className="w-full h-full object-contain" />
        </Link>
        <div className="flex items-center space-x-4 ml-auto">
          <AnimatePresence>
            <div ref={nodeRef}>
              {/* Render the mobile menu when isMenuOpen is true */}
              {isMenuOpen && renderMenuItems("flex flex-col absolute top-full right-0 bg-white dark:bg-opacity-70 rounded-lg shadow-lg p-4 lg:hidden space-y-2")}
            </div>
          </AnimatePresence>
          {/* Render the desktop menu */}
          {renderMenuItems("hidden lg:flex flex-row items-center space-x-4")}
          {/* <ThemeToggle /> */}
          <LanguageSwitcher />
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="lg:hidden dark:text-gray-700">
            {isMenuOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>
      </div>
      {/* Spacer to ensure the header doesn't overlap content */}
      <div className="h-20"></div>
    </div>
  );
};

export default Header;
