/**
 * @fileOverview This file contains the file uploader component for common use cases.
 * @see {@link https://react-dropzone.js.org/ https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import BaseFileUploader from './BaseFileUploader';
import PDFUploader from './PDFUploader';
import ImageUploader from './ImageUploader';
import VideoUploader from './VideoUploader';
import { FiTrash2, FiRefreshCw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const FileUploader = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]); // Tracks successfully loaded files
  const [failedFiles, setFailedFiles] = useState([]); // Tracks files that failed to load

  // Supported file types and their corresponding extensions
  const acceptTypes = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  };
  const maxSize = 50 * 1024 * 1024; // Maximum file size in bytes

  // Translation keys for various messages
  const messages = {
    uploadFailedFiles: t('upload.uploadFailedFiles'),
    fileSizeExceeded: t('upload.fileTooLarge', { maxSize: maxSize / (1024 * 1024) }),
    uploadFiles: t('upload.uploadFiles')
  };

  // Handles adding new files to state
  const handleFilesChange = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Handles tracking of failed file uploads
  const handleFailedFiles = (newFailedFiles) => {
    setFailedFiles((prevFiles) => {
      const filteredNewFailedFiles = newFailedFiles.filter(newFailedFile =>
        !prevFiles.some(failedFile => failedFile.file.name === newFailedFile.file.name)
      );
      return [...prevFiles, ...filteredNewFailedFiles];
    });
  };

  // Removes a file from both successful and failed lists and revokes object URL to free up memory
  const handleRemove = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    setFailedFiles((prevFiles) => prevFiles.filter(({ file }) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  // Attempts to retry uploading a file, revalidating it against the max size constraint
  const handleRetry = (fileToRetry) => {
    const validFiles = [fileToRetry].filter(file => file.size <= maxSize);
    const invalidFiles = [];

    if (validFiles.length === 0) {
      invalidFiles.push({ file: fileToRetry, errors: [{ message: messages.fileSizeExceeded }] });
    }

    if (invalidFiles.length > 0) {
      handleFailedFiles(invalidFiles);
    } else {
      handleFilesChange(validFiles);
    }
  };

  // Allows renaming a file, ensuring the original file extension remains unchanged
  const handleRename = (fileToRename, newFileName) => {
    const originalExtension = fileToRename.name.split('.').pop();
    const newFileNameWithExtension = newFileName.includes(`.${originalExtension}`)
      ? newFileName
      : `${newFileName}.${originalExtension}`;

    setFiles((prevFiles) => {
      const newFiles = prevFiles.map((file) => {
        if (file === fileToRename) {
          const renamedFile = new File([file], newFileNameWithExtension, { type: file.type });
          renamedFile.preview = file.preview;
          return renamedFile;
        }
        return file;
      });
      return newFiles;
    });
  };

  // Submits all successfully loaded files
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted data:', files);
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <form onSubmit={handleSubmit}>
        <BaseFileUploader
          accept={acceptTypes}
          maxFiles={5}
          maxSize={maxSize}
          onFilesChange={handleFilesChange}
          onFailedFiles={handleFailedFiles}
        >
          <>
            <PDFUploader files={files.filter(file => file && file.type === 'application/pdf')} onRemove={handleRemove} onRename={handleRename} onRetry={handleRetry} retryFile={handleRetry} />
            <ImageUploader files={files.filter(file => file && file.type.startsWith('image/'))} onRemove={handleRemove} onRename={handleRename} onRetry={handleRetry} retryFile={handleRetry} />
            <VideoUploader files={files.filter(file => file && (file.type === 'video/mp4' || file.type === 'video/quicktime'))} onRemove={handleRemove} onRename={handleRename} onRetry={handleRetry} retryFile={handleRetry} />
          </>
        </BaseFileUploader>
        <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">
          {messages.uploadFiles}
        </button>
      </form>
      {failedFiles.length > 0 && (
        <div className="mt-4">
          <h2 className="text-red-600">{messages.uploadFailedFiles}</h2>
          {failedFiles.map(({ file, errors }, index) => (
            <div key={file.name + index} className="flex items-center justify-between bg-red-100 p-2 mb-2">
              <div className="flex items-center">
                <p className="text-red-600">{file.name} - {errors.map(error => error.message).join(', ')}</p>
              </div>
              <button type="button" onClick={() => handleRetry(file)} className="text-blue-600 hover:text-blue-800">
                <FiRefreshCw />
              </button>
              <button type="button" onClick={() => handleRemove(file)} className="text-red-600 hover:text-red-800">
                <FiTrash2 />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
