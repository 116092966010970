/**
 * @fileoverview This component is a dropdown component that displays a list of options. 
 * This component is used in the BooksTab component to display the items per page, sort, 
 * and date options. This component is a child component of the BooksTab component.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React from 'react';
import { Button, Label, ListBox, ListBoxItem, Popover, Select, SelectValue } from 'react-aria-components';
import CheckIcon from '@spectrum-icons/workflow/Checkmark';

// Dropdown component that handles selection of options from a dropdown menu
const Dropdown = ({ label, options, onSelect }) => {
  // Function to handle selection changes, converts the key to an index to retrieve the selected option
  const handleSelectionChange = (selectedKey) => {
    const selectedIndex = parseInt(selectedKey.replace('react-aria-', ''), 10) - 1;
    const selectedOption = options[selectedIndex];
    onSelect(selectedOption);
  };

  // Render the dropdown using Select from react-aria-components with custom styling
  return (
    <div className="flex justify-center">
      <Select
        onSelectionChange={handleSelectionChange}
        className="flex flex-col gap-1 w-[200px]"
        placeholder={`${label}`}
      >
        <Label className="text-white cursor-default">
          {label}
        </Label>
        <Button className="flex items-center cursor-default rounded-lg border-0 bg-white bg-opacity-90 pressed:bg-opacity-100 transition py-2 pl-5 pr-2 text-base text-left leading-normal shadow-md text-gray-700 focus:outline-none focus-visible:ring-2 ring-white ring-offset-2 ring-offset-rose-700">
          <SelectValue className="flex-1 truncate placeholder-shown:italic" />
          <span aria-hidden="true">▼</span>
        </Button>
        <Popover className="max-h-60 w-[--trigger-width] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
          <ListBox className="outline-none p-1">
            {options.map((option, index) => (
              <DropdownItem key={`react-aria-${index + 1}`} value={option}>
                {option}
              </DropdownItem>
            ))}
          </ListBox>
        </Popover>
      </Select>
    </div>
  );
}

// Component for each item in the dropdown menu, includes selection feedback
const DropdownItem = ({ children, value, ...props }) => {
  return (
    <ListBoxItem
      {...props}
      className="group flex items-center gap-2 cursor-pointer select-none py-2 px-4 outline-none rounded text-gray-900 focus:bg-indigo-600 focus:text-white"
      textValue={value}
    >
      {({ isSelected }) => (
        <>
          <span className="flex-1 flex items-center gap-2 truncate font-normal group-selected:font-medium">
            {children}
          </span>
          <span className="w-5 flex items-center text-rose-600 group-focus:text-white">
            {isSelected && <CheckIcon size="S" />}
          </span>
        </>
      )}
    </ListBoxItem>
  );
};

export default Dropdown;
