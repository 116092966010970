import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiGlobe } from 'react-icons/fi';
import { FaFlagUsa, FaCanadianMapleLeaf } from 'react-icons/fa';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <div className="language-switcher flex items-center space-x-4 z-10">
      <button
        className="p-2 text-sm text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300"
        onClick={toggleLanguage}
        aria-label="Toggle Language"
      >
        <FiGlobe />
      </button>
      {currentLanguage === 'en' ? (
        <FaFlagUsa className="text-gray-600" />
      ) : (
        <FaCanadianMapleLeaf className="text-gray-600" />
      )}
    </div>
  );
};

export default LanguageSwitcher;
