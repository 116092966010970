/**
 * @fileOverview This file contains the login form for admin.
 * @author Lisi Cao
 * @version 2.0.0
 * @company Iter Innovandi.
 */

import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AccessibleInput from './../AccessibleInput';
import AccessibleButton from './../AccessibleButton';
import ErrorDisplay from './../ErrorDisplay';
import PersonIcon from './../../icons/PersonIcon';
import LockIcon from './../../icons/LockIcon';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, clearError } from '../../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, isAuthenticated, error } = useSelector(state => state.auth);

    const {
        'Username is required': usernameRequired,
        'Password is required': passwordRequired,
        'Enter your username': enterUsername,
        'Enter your password': enterPassword,
        'Login successful!': loginSuccess,
        'Login failed. Please try again.': loginFailed,
        Username: username,
        Password: password,
        'Logging in...': loggingIn,
        Login: login,
    } = t('login', { returnObjects: true });

    const initialValues = {
        username: '',
        password: '',
    };

    const validationSchema = Yup.object({
        username: Yup.string().required(usernameRequired),
        password: Yup.string().required(passwordRequired),
    });

    const handleLogin = (values, { setSubmitting }) => {
        dispatch(clearError());
        dispatch(loginUser(values))
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleInputChange = () => {
        dispatch(clearError());
    };

    useEffect(() => {
        if (isAuthenticated) {
            alert(loginSuccess);
            navigate('/admin');
        }
    }, [isAuthenticated, navigate, loginSuccess]);

    return (
        <div className="max-w-sm mx-auto mt-10 p-6 bg-transparent">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
                onChange={handleInputChange}
            >
                {({ isSubmitting, errors, handleChange }) => (
                    <Form>
                        {/* Username input with icon and accessibility enhancements */}
                        <div className="mb-6">
                            <Field name="username">
                                {({ field }) => (
                                    <div className='flex flex-col space-y-2'>
                                        <AccessibleInput
                                            {...field}
                                            label={username}
                                            showLabel={false}
                                            placeholder={enterUsername}
                                            icon={<PersonIcon />}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange();
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                        
                        {/* Password input with icon, type "password" to hide text */}
                        <div className="mb-6">
                            <Field name="password">
                                {({ field }) => (
                                    <div className='flex flex-col space-y-2'>
                                        <AccessibleInput
                                            {...field}
                                            type="password"
                                            label={password}
                                            showLabel={false}
                                            placeholder={enterPassword}
                                            icon={<LockIcon />}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange();
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                        
                        {/* Error display section, shows login errors or field validation errors */}
                        <div className="mb-6">
                            {Object.keys(errors).length === 0 && error && (
                                <ErrorDisplay message={loginFailed} />
                            )}
                            {Object.keys(errors).length > 0 && (
                                <ErrorDisplay message={errors[Object.keys(errors)[0]]} />
                            )}
                        </div>
    
                        {/* Login button that shows loading state when submitting */}
                        <div className="flex justify-center">
                            <AccessibleButton
                                label={isSubmitting || isLoading ? loggingIn : login}
                                type="submit"
                                isLoading={isSubmitting || isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoginForm;
