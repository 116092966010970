import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Title, Paragraph } from "../Typography"; 
import apiUser from "../../api/apiUser";
import CustomButton from "../landing/companies/CustomButton";

const PreviousIssues = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [previousBook, setPreviousBook] = useState(null); 
  const [isHover, setIsHover] = useState(null);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const response = await apiUser.get(`/api/books`);        
        const sortedBooks = response.data.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setPreviousBook(sortedBooks.slice(1));
      } catch (error) {
        console.log("Error fetching books");
        
      }
    };

    fetchBook();
  }, [currentLanguage]);

  const handleClick = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.log("No file available to download");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
  if (!previousBook) {
    return <div>Loading...</div>;
  }  

  return (
    <div className="container mx-auto px-4 py-12">
      <Title level={2}>
        {t("EXPLORE_PREVIOUS_ISSUES")}
      </Title>
      <Slider {...settings}>
        {previousBook.map((issue, index) => (
          <div 
            key={issue._id} 
            className="mx-auto p-4"
            onMouseEnter={() => setIsHover(index)}
            onMouseLeave={() => setIsHover(null)}  
          >
            <div
              className={`relative w-full h-auto max-w-xs mx-auto rounded-lg overflow-hidden`}
            >
              <img
                src={issue.imageURL}
                alt={"Issue " + issue._id}
                className={`w-full h-auto rounded-lg object-cover ${isHover === index ? 'opacity-30' : ''}`}
              />
              {isHover === index && (
                <CustomButton
                  onClick={() => handleClick(issue.bookURL)}
                  label={t("DOWNLOAD")}
                  className="absolute inset-0 my-auto h-1/6 flex items-center justify-center rounded-lg opacity-100"
                />
              )}
            </div>
            
            <Paragraph size="small" className="text-center mt-2 mx-4">
              {issue.description}
            </Paragraph>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PreviousIssues;
