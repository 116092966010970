import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Helper function to have a more smooth scroll to the about section

function ScrollToHash() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const scrollToElement = () => {
        let element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      setTimeout(scrollToElement, 100);
    }
  }, [location]);


  return null;
}

export default ScrollToHash;