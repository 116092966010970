import React from "react";
import { Title, Paragraph } from "../Typography";
import { useTranslation } from "react-i18next";
import { FaLongArrowAltRight } from "react-icons/fa";

const PartnersValue = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 mb-4 ">
        <div className="relative w-full h-screen overflow-hidden">
          <div
            className="absolute inset-0  bg-cover bg-center transform scale-x-[-1] opacity-50 bg-blend-gradient"
            style={{ backgroundImage: "url('/images/iterinnovandi5.png')" }}
          ></div>
          <div className="absolute top-32  items-center justify-center h-full ">
            <img src="/images/partnership.png" alt="partnership" />
          </div>
        </div>
        <div className=" grid grid-row-4 justify-center mt-20  xl:ml-32 ">
          <div className=" justify-center text-center  xl:text-left">
            <Title level={1} className="mb-0 text-6xl xl:text-8xl font-semobold text-black">
              {t("intro2")}
            </Title>
          </div>

          <div>
            <Paragraph size={"base"} className="text-black font-semibold mt-20 text-2xl text-center xl:text-left">
              {t("body.run")} <br />
              {t("body.work")}
            </Paragraph>
          </div>
          <div></div>
          <div className="xl:p-6 xl:pt-10 mt-20 w-screen h-[350px] xl:w-[613px] xl:h-[414px] group grid grid-rows-2 gap-2 transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
            <div className="  flex items-center justify-center rounded ">
              <img
                src="/images/mila.png"
                alt="Mila"
                className=" xl:max-h-full xl:w-[350px] w-[200px] h-auto bg-transparent  dark:filter dark:invert "
              />
            </div>

            <div className="invisible group-hover:visible text-center   ">
              <Paragraph size="small">
                {t("partner.mila")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>

  {/* second row */}
      <div className="grid grid-cols-1 xl:grid-cols-3 xl:mb-4 mb-0">
        <div className="xl:m-4 group xl:w-[409px] xl:h-[500px] h-[409px] flex justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-2 xl:w-[409px] xl:h-[409px] xl:m-8">
            <div className="flex justify-center items-center xl:mb-10">
              <img
                  src="/images/microsoft.png"
                  alt="Microsoft"
                  className=" w-[200px] xl:w-[356px] h-auto bg-transparent dark:filter dark:invert"
                />
            </div>
            <div className="invisible group-hover:visible  text-center ">
              <Paragraph size="small">
                {t("partner.ms")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>

        <div className="xl:m-4 group xl:w-[409px] xl:h-[500px] h-[409px] flex justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-2 xl:w-[409px] xl:h-[409px] xl:m-8">
            <div className="flex justify-center items-center">
              <img
                src="images/AWS.svg"
                alt="AWS"
                className=" w-[300px] h-auto bg-transparent dark:filter dark:invert"
              />
            </div>
            <div className="invisible group-hover:visible text-center  ">
              <Paragraph size="small">
                {t("partner.aws")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>

        <div className="xl:m-4 pt-10 xl:pt-0 group xl:w-[409px] xl:h-[500px] h-[409px] flex justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-4 xl:w-[409px] xl:h-[409px] xl:m-8">
            <div className="flex justify-center items-center xl:mt-5">
              <img
                src="/ibm.png"
                alt="IBM"
                className=" w-[200px] xl:w-[356px] h-auto bg-transparent dark:filter dark:invert"
                />
            </div>
            <div className="invisible group-hover:visible  text-center ">
              <Paragraph size="small">
                {t("partner.ibm")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* third row */}
      <div className="grid grid-cols-1 xl:grid-cols-3 xl:mb-4 mb-0">
        <div className="xl:m-4 group xl:w-[409px] xl:h-[500px] h-[409px] flex justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-2 xl:w-[409px] xl:h-[409px] xl:m-8">
            <div className="flex justify-center items-center xl:mb-10">
              <img
                src="/images/kws.png"
                alt="KWS"
                className=" xl:w-[400px] w-[300px] h-auto bg-transparent dark:filter dark:invert"
              />
            </div>
            <div className="invisible group-hover:visible  text-center ">
              <Paragraph size="small">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Quibusdam beatae ut delectus autem, voluptatum a.
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>

        <div className="xl:m-4 group xl:w-[409px] xl:h-[500px] h-[409px]  flex  justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-2 xl:w-[409px] xl:h-[409px]  xl:m-8">
            <div className="flex justify-center items-center">
              <img
                src="images/trampoline.png"
                alt="Trampoline"
                className=" w-[300px] h-auto bg-transparent dark:filter dark:invert"
              />
            </div>
            <div className="invisible group-hover:visible text-center  ">
              <Paragraph size="small">
                {t("partner.trampoline")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>

        <div className="xl:m-4 pt-10 xl:pt-0 group  xl:w-[409px] xl:h-[500px] h-[409px] flex justify-center transition-all duration-700 hover:bg-white hover:bg-opacity-70 rounded">
          <div className="grid grid-rows-2 gap-4 xl:w-[409px] xl:h-[409px]  xl:m-8">
            <div className="flex justify-center items-center xl:mt-10">
              <img
                src="/images/obi.png"
                alt="obi"
                className=" xl:h-[300px] xl:w-[300px] w-[200px] h-auto bg-transparent dark:filter dark:invert"
              />
            </div>
            <div className="invisible group-hover:visible  text-center ">
              <Paragraph size="small">
                {t("partner.obivee")}
              </Paragraph>
              <a
                className="text-black font-bold inline-flex items-center "
              >
                Click to learn more <FaLongArrowAltRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersValue;
