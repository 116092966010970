/**
 * @fileOverview This file contains the authentication service for the application.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */
// import apiClient from './apiClient';
import apiClient from './apiMock';

export const loginApi = async (credentials) => {
    const response = await apiClient.post('auth/login', credentials);
    const { token, refreshToken } = response.data.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    return { token, refreshToken };
};

export const registerApi = async (userData) => {
    // Set the default role to 'user' if it is not provided
    const dataWithDefaultRole = { ...userData, role: userData.role || 'user' };
    const response = await apiClient.post('auth/register', dataWithDefaultRole);
    const { token, refreshToken } = response.data.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    return { token, refreshToken };
};

export const logoutApi = async () => {
    await apiClient.post('api/auth/logout');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

export const requestPasswordResetApi = async (email) => {
    const response = await apiClient.post('auth/request-password-reset', { email });
    return response.data;
};

export const resetPasswordApi = async (token, newPassword) => {
    const response = await apiClient.post('auth/reset-password', { token, newPassword });
    return response.data;
};

export const changePasswordApi = async (currentPassword, newPassword) => {
    const response = await apiClient.post('auth/change-password', { currentPassword, newPassword });
    return response.data;
};