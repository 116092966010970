/**
 * @fileoverview This component contains logics related to search feature for admin.
 * @author Lisi Cao
 * @version 1.1.1
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaSearch, FaTimes } from 'react-icons/fa';
import AccessibleInput from './../../AccessibleInput';
import BaseButton from './../../BaseButton';

// Custom button component with accessibility features
const CustomButton = ({ onPress, disabled = false, ariaLabel, children }) => {
  return (
    <BaseButton
      onPress={onPress}
      disabled={disabled}
      aria-label={ariaLabel}
      className='p-2 bg-gray-300 hover:bg-gray-400 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-300 ease-in-out
      dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-300 dark:focus:ring-opacity-50 dark:text-gray-300 dark:hover:text-gray-200 dark:focus:text-gray-200'
    >
      {children}
    </BaseButton>
  );
};

CustomButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Updates the search term in state on input change
  const handleInputChange = (e) => {
    if (e && e.target) {
      setSearchTerm(e.target.value);
    }
  };

  // Initiates the search action and clears the input after search
  const handleSearch = () => {
    onSearch(searchTerm.trim());
    setSearchTerm(''); 
  };

  // Clears the current search input and the search results
  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };

  // Triggers the search action when the Enter key is pressed
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='flex justify-center items-center space-x-2 my-4'>
      <AccessibleInput
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        type="text"
        name="searchTerm"
        placeholder="Search..."
        className='w-full px-3 py-2 border rounded-md text-sm'
        aria-label="Search input"
      />
      {searchTerm && (
        <CustomButton
          onPress={handleClear}
          ariaLabel="Clear search"
        >
          <FaTimes />
        </CustomButton>
      )}
      <CustomButton
        onPress={handleSearch}
        isDisabled={searchTerm.trim() === ''}
        ariaLabel="Search"
      >
        <FaSearch />
      </CustomButton>
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
