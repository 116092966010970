/**
 * @fileoverview This component contains the VideosTab component that displays a list of video items with a thumbnail, title, author, link, and description. This component is used in the Admin component to display the Videos tab.
 * @author Lisi Cao
 * @version 2.1.0
 * @company Iter Innovandi.
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Card from "./tab/Card";
import Pagination from "../Pagination";
import SearchBar from "./tab/SearchBar";
import Dropdown from "./tab/Dropdown";
import NewItem from "./tab/NewItem";
import ItemFormModal from "./ItemFormModal";
import { fetchVideos, createVideo, updateVideo, deleteVideo } from '../../../redux/video/videoSlice';

const VideosTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [5, 10, 15, 20];
  const sortOptions = ["Recommended", "Sort by A-Z", "Sort by Z-A", "Newest"];
  const dateOptions = ["All Time", "Yesterday", "Last Week", "This Month", "This Year", "Another Period"];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [dateOption, setDateOption] = useState(dateOptions[0]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const dispatch = useDispatch();
  const { entities: data, loading } = useSelector(state => state.videos);

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleHide = (id) => {
    console.log(`Hide item with id: ${id}`);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setFormModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteVideo(id))
      .then(() => {
        console.log(`Video with id ${id} deleted successfully.`);
      })
      .catch((error) => {
        console.error(`Failed to delete video with id ${id}:`, error);
      });
  };

  const handleSearch = (term) => {
    setCurrentPage(1);
    if (term === '') {
      setFilteredData(data);
    } else {
      const lowercasedTerm = term.toLowerCase();
      const filtered = data.filter(item =>
        item.title.toLowerCase().includes(lowercasedTerm) ||
        item.author.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredData(filtered);
    }
  };

  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (SelectValue) => {
    console.log(`Items per page: ${SelectValue}`);
    setItemsPerPage(parseInt(SelectValue, 10));
    setCurrentPage(1);
  };

  const handleFormSubmit = (values, { setSubmitting, setStatus }) => {
    if (currentItem) {
      dispatch(updateVideo({ id: currentItem.id, videoData: values }))
        .then(() => {
          setStatus({ success: 'Video updated successfully!' });
          setFormModalOpen(false);
          setCurrentItem(null);
        });
    } else {
      dispatch(createVideo(values))
        .then(() => {
          setStatus({ success: 'Video created successfully!' });
          setFormModalOpen(false);
        });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, filteredData]);

  return (
    <div>
      <div className="flex items-center justify-between mx-4">
        <NewItem />
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="flex items-center justify-around space-x-4 mb-4">
        <Dropdown
          label="Date"
          options={dateOptions}
          selectedOption={dateOption}
          onSelect={setDateOption}
        />
        <Dropdown
          label="Sort By"
          options={sortOptions}
          selectedOption={sortOption}
          onSelect={setSortOption}
        />
        <Dropdown
          label="Show"
          options={itemsPerPageOptions}
          selectedOption={itemsPerPage}
          onSelect={handleItemsPerPageChange}
        />
      </div>
      <div className="p-4 m-4 grid grid-cols-1 gap-4">
        {loading ? (
          <div>Loading...</div>
        ) : (
          currentData.map(item => (
            <Card
              key={item.id}
              thumbnail={item.thumbnail}
              title={item.title}
              link={item.link}
              description={item.description}
              onHide={() => handleHide(item.id)}
              onEdit={() => handleEdit(item)}
              onDelete={() => handleDelete(item.id)}
            />
          ))
        )}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      {isFormModalOpen && (
        <ItemFormModal
          isOpen={isFormModalOpen}
          onRequestClose={() => setFormModalOpen(false)}
          initialValues={currentItem || { title: '', description: '', link: '', author: '' }}
          onSubmit={handleFormSubmit}
          isEditing={!!currentItem}
          formType="Videos"
        />
      )}
    </div>
  );
};

export default VideosTab;