/**
 * @fileOverview This file contains the video slice for the application.
 * @author Lisi Cao
 * @version 1.1.1
 * @company Iter Innovandi.
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVideosApi, addVideoApi, updateVideoApi, deleteVideoApi } from '../../api/videoService';

export const fetchVideos = createAsyncThunk('videos/fetch', async (_, { rejectWithValue }) => {
  try {
    const videos = await getVideosApi();
    return videos;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createVideo = createAsyncThunk('videos/create', async (video, { rejectWithValue }) => {
  try {
    const newVideo = await addVideoApi(video);
    return newVideo;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateVideo = createAsyncThunk('videos/update', async ({ id, videoData }, { rejectWithValue }) => {
  try {
    const updatedVideo = await updateVideoApi(id, videoData);
    return updatedVideo;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const deleteVideo = createAsyncThunk('videos/delete', async (id, { rejectWithValue }) => {
  try {
    await deleteVideoApi(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const videoSlice = createSlice({
  name: 'videos',
  initialState: {
    entities: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.entities = action.payload || [];
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createVideo.fulfilled, (state, action) => {
        state.entities.push(action.payload);
        state.error = null;
      })
      .addCase(createVideo.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateVideo.fulfilled, (state, action) => {
        const index = state.entities.findIndex(video => video.id === action.payload.id);
        if (index !== -1) {
          state.entities[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.entities = state.entities.filter(video => video.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
});

export default videoSlice.reducer;
