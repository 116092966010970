/**
 * @fileoverview This component contains a card that displays a book item with a thumbnail, title, author, link, and description. This component is used in the BooksTab component to display each book item
 * @author Lisi Cao
 * @version 1.1.0
 * @company Iter Innovandi.
 */

import React from 'react';
import { Paragraph, Strong } from '../../../Typography';
import { Link } from 'react-aria-components';
import { HiOutlineEyeOff, HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';

const Card = ({ thumbnail, title, author = "", link, description, onHide, onEdit, onDelete }) => {
    return (
        <div className="w-full bg-gray-200 dark:bg-gray-700 shadow-lg rounded-xl overflow-hidden">
            <div className="grid grid-cols-12">
                {/* Thumbnail */}
                <div className="col-span-12 md:col-span-3 bg-gray-800 flex items-center justify-center">
                    <img src={thumbnail} alt={title} className="object-cover h-full w-full" />
                </div>
                {/* Content */}
                <div className="col-span-12 md:col-span-6 p-4 flex flex-col justify-center">
                    <div className="space-y-4">
                        <Paragraph size='base' ><Strong>{title}</Strong></Paragraph>
                        {author && <Paragraph size='small' >{author}</Paragraph>}
                        <Paragraph size='small'><Link href={link} target="_blank" rel="noreferrer" >{link}</Link></Paragraph>
                        <Paragraph size='small' >{description}</Paragraph>
                    </div>
                </div>
                {/* Actions */}
                <div className="col-span-12 md:col-span-3 p-4 flex items-center justify-center">
                    <div className="flex flex-row md:flex-col space-x-4 md:space-x-0 md:space-y-4">
                        <button onClick={onHide} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                            <HiOutlineEyeOff className="h-6 w-6" />
                        </button>
                        <button onClick={onEdit} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                            <HiOutlinePencilAlt className="h-6 w-6" />
                        </button>
                        <button onClick={onDelete} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                            <HiOutlineTrash className="h-6 w-6" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;
