/**
 * @fileOverview This file contains the layout of the login page.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout/Layout';
import LoginForm from '../../components/admin/login/LoginForm';
import { Title } from '../../components/Typography';

const Section = ({ children }) => {
    return (
        <div className='w-full md:w-1/2 flex flex-col justify-center items-center bg-transparent'>
            <div className='max-w-md w-full min-w-[300px] md:min-w-[400px] p-8'>
                {children}
            </div>
        </div>
    );
};

const LoginPage = () => {
    const { t } = useTranslation();
    const {
        "Admin Login": adminLogin,
    } = t('login', { returnObjects: true });

    return (
        <Layout>
            <div className='relative flex flex-col md:flex-row min-h-screen'>
                <Section>
                    <img src='/images/admin-login.png' alt='Admin Login Background' className='object-cover w-full h-full' />
                </Section>
                <Section>
                    <Title level={2} className='mb-8 text-center'>{adminLogin}</Title>
                    <LoginForm />
                </Section>
            </div>
        </Layout>
    );
};

export default LoginPage;
