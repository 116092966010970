import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Title = ({ level, className = '', children }) => {
  const Tag = `h${level}`;
  const classMapping = {
    1: 'text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-4 lg:mb-8 font-bold font-jost',
    2: 'text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-3 lg:mb-6 font-bold font-jost',
    3: 'text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-3 lg:mb-6 font-bold font-jost',
    4: 'text-lg sm:text-xl md:text-2xl lg:text-3xl mb-2 lg:mb-4 font-bold font-jost',
  };
  const titleClass = classMapping[level] || '';

  return <Tag className={classNames(titleClass, className)}>{children}</Tag>;
};

Title.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Paragraph = ({ className = '', children, size = 'base' }) => {
  const sizeClassMapping = {
    small: 'text-sm leading-relaxed sm:text-base md:text-lg lg:text-xl mb-4 font-poppins',
    base: 'text-base leading-relaxed sm:text-lg md:text-xl lg:text-2xl mb-4 font-poppins',
    large: 'text-lg leading-relaxed sm:text-xl md:text-2xl lg:text-3xl mb-4 font-poppins',
  };
  const sizeClass = sizeClassMapping[size];

  return <p className={classNames(sizeClass, className)}>{children}</p>;
};

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'base', 'large']),
};

const Strong = ({ children }) => {
  return <strong className="font-semibold">{children}</strong>;
};

Strong.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Title, Paragraph, Strong };
