import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { Title, Paragraph } from "../Typography";

const Footer = () => {

  const { t } = useTranslation();
  const menuItems = [
    { key: "about", label: t("header.about") },
    // { key: "books-page", label: t("header.books") },
    { key: "videos-page", label: t("header.videos") },
    { key: "projects-page", label: t("header.projects") },
    { key: "partners-page", label: t("header.partners") },
  ];

  return (
    <div className="bottom-0 w-full bg-gradient-to-t from-white dark:from-gray-300 to-transparent text-gray-900 p-6"
      style={{ zIndex: 2000 }}>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">

        {/* Logo section */}
        <div className="col-span-1 flex justify-center md:justify-start dark:filter dark:invert-[30%]">
          <img src="/images/logo.png" alt="logo" className="w-32 md:w-40 h-auto" />
        </div>
        
        {/* Navigation links */}
        <div className="col-span-2 flex flex-wrap justify-between space-x-3 md:space-x-4 mt-4 md:mt-0">
          {menuItems.map(item => (
            <Link to={item.key==="about" ? `/#${item.key}`:`/${item.key}`} key={item.key} className="relative text-lg font-semibold dark:text-gray-700 group">
              {item.label}
              <span className="absolute bottom-0 left-0 w-0 h-2 bg-blue-500 transition-all duration-300 ease-out group-hover:w-full bg-opacity-50 rounded-xl"></span>
            </Link>
          ))}
        </div>
        
        {/* Empty column for spacing */}
        <div className="col-span-1"></div>

        {/* Office address */}
        <div className="col-span-1 mt-4 text-center dark:text-gray-600 md:text-left">
          <div className="flex items-center space-x-4"> {/* Flexbox container */}
            <Title level={4} className="font-semibold">{t('office')}</Title>
            <Paragraph>407 McGill ST,</Paragraph>
          </div>
          <Paragraph>Montreal, QC, H2Y 2G3</Paragraph>
        </div>
        
        {/* Social media links */}
        <div className="col-span-2 mt-4 flex justify-center space-x-0 md:space-x-4 dark:text-gray-600">
          {/* Instagram link with aria-label for accessibility */}
          <Link to="https://www.instagram.com/iter.innovandi/" target="_blank" rel="noopener noreferrer" className="text-3xl md:text-4xl" aria-label="Follow us on Instagram">
            <FaInstagram />
          </Link>
          {/* LinkedIn link with aria-label for accessibility */}
          <Link to="https://www.linkedin.com/company/iter-innovandi/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="text-3xl md:text-4xl" aria-label="Connect with us on LinkedIn">
            <FaLinkedin />
          </Link>
          {/* Youtube link with aria-label for accessibility */}
          <Link to="https://www.youtube.com/@IterInnovandi" target="_blank" rel="noopener noreferrer" className="text-3xl md:text-4xl" aria-label="Follow us on Youtube">
            <FaYoutube />
          </Link>
        </div>
        
        {/* Contact information */}
        <div className="col-span-1 mt-4 text-center md:text-right dark:text-gray-600">
          <Title level={4} className="font-semibold">info@iterinnovandi.com</Title>
          <Paragraph size="small">+1 ( 438 ) 619 - 4242 (Montréal)</Paragraph>
          <Paragraph size="small">+1 ( 647 ) 874 - 6071 (Toronto)</Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Footer;
