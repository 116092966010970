/**
 * @fileOverview This file contains a base button for common use cases.
 * @author Lisi Cao
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import PropTypes from 'prop-types';

const BaseButton = ({ onPress, className = '', isLoading = false, children, ...props }) => {
    const ref = useRef();

    let { buttonProps } = useButton(
        {
            isDisabled: isLoading,
            onPress,
            ...props
        },
        ref
    );

    return (
        <button
            {...mergeProps(buttonProps, props)}
            ref={ref}
            className={`px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${className}`}
            disabled={isLoading}
            aria-live='polite'
        >
            {isLoading ? (
                <span className='loader'></span>
            ) : (
                children
            )}
        </button>
    )
}

BaseButton.propTypes = {
    onPress: PropTypes.func,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default BaseButton;