/**
 * @fileOverview This file contains the PDF uploader component for common use cases.
 * @author Lisi Cao
 * @see {@link https://react-pdf-viewer.js.org/ }
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { FiDownload, FiTrash2, FiZoomIn, FiEdit } from 'react-icons/fi';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import RenameFile from './RenameFile';
import { Paragraph } from '../Typography';

const PDFUploader = ({ files, onRemove, onRename = () => { } }) => {
  const workerUrl = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPDF, setCurrentPDF] = useState(null);
  const [renamingFile, setRenamingFile] = useState(null);

  const handleRemove = (file) => {
    if (renamingFile === file) {
      setRenamingFile(null);
    }
    onRemove(file);
    URL.revokeObjectURL(file.preview);
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    link.click();
  };

  const handleZoomIn = (file) => {
    setCurrentPDF(file.preview);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPDF(null);
  };

  const handleRename = (file) => {
    setRenamingFile(file);
  };

  const cancelRename = () => {
    setRenamingFile(null);
  };

  return (
    <div className="mt-4">
      <Worker workerUrl={workerUrl}>
        {files.map((file, index) => (
          <div key={file.name + index} className="flex items-center justify-between bg-gray-100 p-2 mb-2">
            <div className="flex items-center">
              <div className="w-12 h-12 flex items-center justify-center mr-2">
                <Viewer fileUrl={file.preview} />
              </div>
              <Paragraph size='base'>{file.name}</Paragraph>
            </div>
            <div className="flex items-center">
              <button type="button" onClick={() => handleZoomIn(file)} className="text-green-600 hover:text-green-800 mr-2" aria-label={`Zoom In ${file.name}`}>
                <FiZoomIn />
              </button>
              <button type="button" onClick={() => handleDownload(file)} className="text-blue-600 hover:text-blue-800 mr-2" aria-label={`Download ${file.name}`}>
                <FiDownload />
              </button>
              <button type="button" onClick={() => handleRename(file)} className="text-yellow-600 hover:text-yellow-800 mr-2" aria-label={`Rename ${file.name}`}>
                <FiEdit />
              </button>
              <button type="button" onClick={() => handleRemove(file)} className="text-red-600 hover:text-red-800" aria-label={`Remove ${file.name}`}>
                <FiTrash2 />
              </button>
            </div>
          </div>
        ))}
      </Worker>

      <RenameFile renamingFile={renamingFile} onRename={onRename} onCancel={cancelRename} />

      <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="PDF Preview">
        {currentPDF ? (
          <div className="h-96">
            <Viewer fileUrl={currentPDF} />
          </div>
        ) : (
          <div>No PDF selected</div>
        )}
      </CustomModal>
    </div>
  );
};

PDFUploader.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func,
};

export default PDFUploader;
