/**
 * @fileOverview This file contains the base file uploader component for common use cases.
 * @author Lisi Cao
 * @see {@link https://react-dropzone.js.org/ https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { FiUpload } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../Typography';

const BaseFileUploader = ({ accept, maxFiles, maxSize, onFilesChange, onFailedFiles, children }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const messages = {
    fileTooLarge: t('upload.fileTooLarge', { maxSize: maxSize / (1024 * 1024) }),
    maxFilesExceeded: t('upload.maxFilesExceeded', { maxFiles }),
    dragAndDrop: t('upload.dragAndDrop')
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    validateFiles(acceptedFiles, fileRejections);
  };

  const validateFiles = (acceptedFiles, fileRejections) => {
    const validFiles = acceptedFiles.filter(file => file.size <= maxSize);
    const invalidFiles = fileRejections.map(rejection => ({ file: rejection.file, errors: rejection.errors }));

    if (invalidFiles.length > 0) {
      setError(messages.fileTooLarge);
      onFailedFiles(invalidFiles);
    } else {
      const newFiles = validFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));

      const existingFilesCount = children && children.props.files ? children.props.files.length : 0;
      if (newFiles.length + existingFilesCount > maxFiles) {
        setError(messages.maxFilesExceeded);
      } else {
        setError('');
        onFilesChange(newFiles);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: true,
    maxSize,
  });

  return (
    <div>
      <div {...getRootProps()} className="border-2 border-dashed border-gray-300 p-6 text-center cursor-pointer">
        <input {...getInputProps()} />
        <Paragraph size='base'>
          <FiUpload className="inline-block text-xl mr-2" />{messages.dragAndDrop}
        </Paragraph>
      </div>
      {error && <div className="text-red-600 mt-2">{error}</div>}
      {children}
    </div>
  );
};

BaseFileUploader.propTypes = {
  accept: PropTypes.object.isRequired,
  maxFiles: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  onFailedFiles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseFileUploader;
