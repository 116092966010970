import React, { createContext, useContext, useEffect, useState } from "react";

// Create a ThemeContext to hold the current theme state
const ThemeContext = createContext();

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider component to manage the theme state and provide it to the rest of the app
export const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = useState(localStorage.getItem("theme") || 'system');
    const [followSystem, setFollowSystem] = useState(theme === 'system');

    useEffect(() => {
        const handleSystemChange = (e) => {
            if (followSystem) {
                setTheme(e.matches ? 'dark' : 'light');
            }
        };

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.addListener(handleSystemChange);

        // Set initial theme based on system preference
        if (followSystem) {
            setTheme(mediaQuery.matches ? 'dark' : 'light');
        }

        // Cleanup function to remove the event listener
        return () => mediaQuery.removeListener(handleSystemChange);
    }, [followSystem]);

    useEffect(() => {
        const root = window.document.documentElement;

        // Apply the appropriate class to the root element based on the current theme
        if (theme === 'dark') {
            root.classList.add('dark');
        } else {
            root.classList.remove('dark');
        }

        // Save the theme to localStorage if it is not 'system'
        if (theme !== 'system') {
            localStorage.setItem("theme", theme);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme, followSystem, setFollowSystem }}>
            {children}
        </ThemeContext.Provider>
    );
};
