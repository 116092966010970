/**
 * @fileOverview This file contains the image uploader component for common use cases.
 * @author Lisi Cao
 * @see {@link https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import { FiDownload, FiTrash2, FiZoomIn, FiEdit } from 'react-icons/fi';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import RenameFile from './RenameFile';
import { Paragraph } from '../Typography';

const ImageUploader = ({ files, onRemove, onRename = () => { } }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [renamingFile, setRenamingFile] = useState(null);

  const handleRemove = (file) => {
    if (renamingFile === file) {
      setRenamingFile(null);
    }
    onRemove(file);
    URL.revokeObjectURL(file.preview);
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    link.click();
  };

  const handleZoomIn = (file) => {
    setCurrentImage(file.preview);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  const handleRename = (file) => {
    setRenamingFile(file);
  };

  const cancelRename = () => {
    setRenamingFile(null);
  };

  return (
    <div className="mt-4">
      {files.map((file, index) => (
        <div key={file.name + index} className="flex items-center justify-between bg-gray-100 p-2 mb-2">
          <div className="flex items-center">
            <img src={file.preview} alt={file.name} className="w-12 h-12 object-cover mr-2" />
            <Paragraph size='base'>{file.name}</Paragraph>
          </div>
          <div className="flex items-center">
            <button type="button" onClick={() => handleZoomIn(file)} className="text-green-600 hover:text-green-800 mr-2">
              <FiZoomIn />
            </button>
            <button type="button" onClick={() => handleDownload(file)} className="text-blue-600 hover:text-blue-800 mr-2">
              <FiDownload />
            </button>
            <button type="button" onClick={() => handleRename(file)} className="text-yellow-600 hover:text-yellow-800 mr-2">
              <FiEdit />
            </button>
            <button type="button" onClick={() => handleRemove(file)} className="text-red-600 hover:text-red-800">
              <FiTrash2 />
            </button>
          </div>
        </div>
      ))}

      <RenameFile renamingFile={renamingFile} onRename={onRename} onCancel={cancelRename} />

      <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Preview">
        {currentImage ? (
          <img src={currentImage} alt="Preview" className="w-full h-full object-contain" />
        ) : (
          <div>No Image selected</div>
        )}
      </CustomModal>
    </div>
  );
};

ImageUploader.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func,
};

export default ImageUploader;
