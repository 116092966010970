/**
 * @fileOverview This file contains the API user for the application. 
 * It is used to make requests to the server and handle errors. 
 * It also contains the request interceptor to add the accept-language header to the requests. 
 * The API user is exported as a default function.
 */

import axios from 'axios';
import i18n from "i18next";

const apiUser = axios.create({
  // baseURL: 'http://localhost:5001',
  baseURL: 'https://iterinnovandiwebsite-g2h4h6b9c3cwgkan.canadacentral-01.azurewebsites.net/',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiUser.interceptors.request.use(
  config => {
  config.headers['Accept-Language'] = i18n.language; 
  return config;
}, 
error => Promise.reject(error));

export default apiUser;
