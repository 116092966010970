import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VideosPage from "./pages/VideosPage";
import LandingPage from "./pages/LandingPage";
import BooksPage from "./pages/BooksPage";
import ProjectsPage from "./pages/ProjectsPage";
import AdminPage from "./pages/admin/AdminPage";
import ScrollToTop from "./utils/ScrollToTop";
import ScrollToHash from "./utils/ScrollToHash";
import LoginPage from "./pages/admin/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import PartnersPage from "./pages/PartnersPage";

const AdminLayout = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ScrollToHash />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/books-page" element={<BooksPage />} /> */}
        <Route path="/videos-page" element={<VideosPage />} />
        <Route path="/projects-page" element={<ProjectsPage />} />
        <Route path="/partners-page" element={<PartnersPage />} />
        <Route path="/admin/*" element={<AdminLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
