/**
 * @fileOverview This file contains a new button component that triggers modals for creating new items (books, videos, projects)
 * @author Lisi Cao
 * @version 3.1.0
 * @company Iter Innovandi.
 */

import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import AccessibleButton from '../../AccessibleButton';
import { FaBook, FaVideo, FaProjectDiagram } from 'react-icons/fa';
import ItemFormModal from '../ItemFormModal';
import CustomModal from '../../../upload/CustomModal';
import { createVideo, updateVideo } from '../../../../redux/video/videoSlice';

const NewItem = () => {
    const [isSelectModalOpen, setSelectModalOpen] = useState(false);
    const [isFormModalOpen, setFormModalOpen] = useState(false);
    const [formType, setFormType] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const dispatch = useDispatch();

    const handleOpenSelectModal = () => setSelectModalOpen(true);
    const handleCloseSelectModal = () => setSelectModalOpen(false);

    const handleOpenFormModal = (type) => {
        setFormType(type);
        setFormModalOpen(true);
    };

    const handleCloseFormModal = () => {
        setFormModalOpen(false);
        setFormType(null);
        setCurrentItem(null);
    };

    const handleFormSubmit = (values, { setSubmitting, setStatus }) => {
        if (formType === 'Videos') {
          if (currentItem) {
            dispatch(updateVideo({ id: currentItem.id, videoData: values }))
              .then(() => {
                setStatus({ success: 'Video updated successfully!' });
                handleCloseFormModal();
              });
          } else {
            dispatch(createVideo(values))
              .then(() => {
                setStatus({ success: 'Video created successfully!' });
                handleCloseFormModal();
              });
          }
        }
        // Add other form types here
      
        setSubmitting(false);
      };
      
    const handleSelectType = (type) => {
        setFormType(type);
        handleCloseSelectModal();
        handleOpenFormModal(type);
    };

    const initialValues = useMemo(() => ({
        title: '',
        description: '',
        link: '',
        author: formType === 'Books' ? '' : undefined, // Only books have author
    }), [formType]);

    return (
        <div className='flex justify-center items-center my-4'>
            <AccessibleButton
                label="New"
                icon={<PlusIcon />}
                onPress={handleOpenSelectModal}
            />
            {isSelectModalOpen && (
                <CustomModal
                    isOpen={isSelectModalOpen}
                    onRequestClose={handleCloseSelectModal}
                    contentLabel="Select Item Type"
                    id="select-item-type-modal"
                >
                    <>
                        <h2 className="text-center text-lg font-bold">Select Item Type</h2>
                        <div className="flex flex-col items-center space-y-4 mt-4">
                            <AccessibleButton
                                label="Books"
                                icon={<FaBook />}
                                onPress={() => handleSelectType('Books')}
                                className="w-40"
                            />
                            <AccessibleButton
                                label="Videos"
                                icon={<FaVideo />}
                                onPress={() => handleSelectType('Videos')}
                                className="w-40"
                            />
                            <AccessibleButton
                                label="Projects"
                                icon={<FaProjectDiagram />}
                                onPress={() => handleSelectType('Projects')}
                                className="w-40"
                            />
                        </div>
                    </>
                </CustomModal>
            )}
            {isFormModalOpen && (
                <ItemFormModal
                    isOpen={isFormModalOpen}
                    onRequestClose={handleCloseFormModal}
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                    isEditing={!!currentItem}
                    formType={formType}
                />
            )}
        </div>
    );
};

const PlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4V20M4 12H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default NewItem;
