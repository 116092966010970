/**
 * @fileOverview This file contains the video uploader component for common use cases.
 * @author Lisi Cao
 * @see {@link https://github.com/lisiCAO/react-components/tree/fileupload}
 * @version 1.0.0
 * @company Iter Innovandi.
 */

import React, { useState } from 'react';
import { FiDownload, FiTrash2, FiEdit } from 'react-icons/fi';
import PropTypes from 'prop-types';
import RenameFile from './RenameFile';
import { Paragraph } from '../Typography';

const VideoUploader = ({ files, onRemove, onRename = () => { } }) => {
  const [renamingFile, setRenamingFile] = useState(null);

  const handleRemove = (file) => {
    if (renamingFile === file) {
      setRenamingFile(null);
    }
    onRemove(file);
    URL.revokeObjectURL(file.preview);
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    link.click();
  };

  const handleRename = (file) => {
    setRenamingFile(file);
  };

  const cancelRename = () => {
    setRenamingFile(null);
  };

  return (
    <div className="mt-4">
      {files.map((file, index) => (
        <div key={file.name + index} className="flex items-center justify-between bg-gray-100 p-2 mb-2">
          <div className="flex items-center">
            <video src={file.preview} controls className="w-12 h-12 mr-2"></video>
            <Paragraph size='base'>{file.name}</Paragraph>
          </div>
          <div className="flex items-center">
            <button type="button" onClick={() => handleDownload(file)} className="text-blue-600 hover:text-blue-800 mr-2">
              <FiDownload />
            </button>
            <button type="button" onClick={() => handleRename(file)} className="text-yellow-600 hover:text-yellow-800 mr-2">
              <FiEdit />
            </button>
            <button type="button" onClick={() => handleRemove(file)} className="text-red-600 hover:text-red-800">
              <FiTrash2 />
            </button>
          </div>
        </div>
      ))}

      <RenameFile renamingFile={renamingFile} onRename={onRename} onCancel={cancelRename} />
    </div>
  );
};

VideoUploader.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func,
};

export default VideoUploader;
