/**
 * @fileOverview This file contains an accessible button for admin.
 * @author Lisi Cao
 * @version 1.1.0
 * @company Iter Innovandi.
 */

import React from 'react';
import BaseButton from './BaseButton';
import PropTypes from 'prop-types';

const AccessibleButton = ({ label, onPress, className = '', icon, isLoading = false, ...props }) => {
    return (
        <BaseButton
            onPress={onPress}
            className={`bg-blue-300 dark:bg-blue-500 
                        text-black dark:text-white font-semibold 
                        overflow-hidden text-ellipsis whitespace-nowrap 
                        rounded-full px-4 py-2 mx-auto 
                        min-w-[7rem] w-auto max-w-full
                        hover:bg-blue-500 dark:hover:bg-blue-600 hover:text-white hover:shadow-md 
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                        flex items-center justify-center ${className}`}
            isLoading={isLoading}
            {...props}
        >
            {icon && <span className="pr-2 flex-shrink-0">{icon}</span>}
            <span className="flex-grow">{label}</span>
        </BaseButton>
    );
};

AccessibleButton.propTypes = {
    label: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
};

export default AccessibleButton;
