import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const defaultLanguage = localStorage.getItem("language") || "fr";

const resources = {
  en: {
    translation: {
      // General
      welcome: "Welcome",
      description: "This is an example application for language switching.",
      switchLanguage: "Switch Language",

      // LoginForm Component
      login: {      
        'Username is required': 'Username is required',
        'Password is required': 'Password is required',
        'Enter your username': 'Enter your username',
        'Enter your password': 'Enter your password',
        'Login successful!': 'Login successful!',
        'Login failed. Please try again.': 'Login failed. Please try again.',
        Username: 'Username',
        Password: 'Password',
        "Logging in...": "Logging in...",
        Login: "Login",
        "Admin Login": "LOGIN AS AN ADMIN USER",
      },

      // AboutUsIntroSection Component
      aboutUsIntroSection: {
        title: 'GET TO KNOW US',
        description1: "We're Iter Innovandi, and we make AI easy for \nevery business.",
        description2: "Our tools are simple, fast, and ready to help your business grow \nand improve quickly. With us, AI isn't just smart, it's easy and \neffective for you!"
      },
      learnMoreButton: 'LEARN MORE',

      //AboutUsProcessSection Component 
      aboutUsProcessSection: {
        title: "OUR AI INTEGRATION PROCESS",
        description1: "Our process is like a custom-built road trip for \nyour business's journey into AI.",
        description2: "We've got five steps that take you from 'AI curious' to 'AI empowered', \nmaking sure we're hitting all the right spots along the way.\nTo start, we're giving you a free consultation and report. It's our gift to \nyou, so you can see how much we can help your business, with no \ncost or commitment. Try us out and see how we make your business \nsmarter, easier."
      },
      //StepsCard Component
      step1: {
        stepNum: '01.',
        title: 'UNDERSTAND YOUR NEEDS',
        description1: "We start by learning about your business to understand your unique needs and goals.",
        description2: 'We sit down, have a \nchat about what your \nbusiness is all about, \nand figure out how AI \ncan fit into the picture'
      },
      step2: {
        stepNum: '02.',
        title: 'CUSTOM AI SOLUTIONS',
        description1: "We create personalized AI solutions designed to fit your business and boost efficiency",
        description2: "We lay out a \nstep-by-step plan, showing you \nhow we’ll bring the AI \nsolutions to life in your \nbusiness."
      },
      step3: {
        stepNum: '03.',
        title: 'PLANNING AN IMPLEMENTATION',
        description1: 'We develop a clear plan and timeline to smoothly integrate AI into your operations.'
      },
      step4: {
        stepNum: '04.',
        title: 'TRAINING AND SUPPORT',
        description1: "We provide training and support to ensure your team can efficiently use the new AI tools.",
        description2: "We stick around, making \nsure your team knows \nhow to get the best out of \nthe new tools."
      },
      step5: {
        stepNum: '05.',
        title: 'ONGOING MAINTENANCE',
        description1: "We offer continous support to keep your AI systems up-to-date and running smoothly.",
        description2: "We’re always on hand to \ntweak, tune, and \ntroubleshoot, making sure \neverything’s running \nsmoothly."
      },

      //AboutUsChartSection Component 
      aboutUsChartSection: {
        title: "DID YOU KNOW?",
      },

      // CaseStudies Component
      caseStudies: "CASE STUDIES",

      case_study1: {
        title: "CASH FLOW INCREASE",
        description1: "A STUDY BY McKINSEY & COMPANY",
        description2: "found that AI adoption could lead to 20-25% increase in cash flow for small businesses.",
      },
      case_study2: {
        title: "REPORTED SUCCESS ",
        description1: "91% OF SMALL BUSINESSES USING AI",
        description2: "reported overall success, indicating a strong correlation between AI use and business success.",
      },
      case_study3: {
        title: "ACHIEVED BENEFITS",
        description1: "SURVEY BY DELOITTE REVEALED",
        description2: "83% of early AI adopters have already achieved substantial (30% or more) economic benefits.",
      },
      case_study4: {
        title: "INCREASED SALES WITH AI",
        description1: "RESEARCH BY SALESFORCE",
        description2: "found that high-performing sales teams are 4.2 times more likely than underperforming to use AI across sales processes..",
      },
      case_study5: {
        title: "MORE OPPORTUNITIES",
        description1: "STUDY BY NARRATIVE SCIENCE",
        description2: "61% of businesses are using AU to identify opportunities in data that would otherwise be missed.",
      },


      // Newsletter Component
      newsletter: {
        title: "STAY IN THE LOOP WITH THE LATEST AI TOOLS",
        description: "Subscribe to our monthly newsletter for cutting-edge AI insights and updates.",
        email : "Enter Your Email",
        button: "SUBSCRIBE",
        successMessage: "Thank you for subscribing to the newsletter",
        userExistMessage: "This email already subscribed to the newsletter",
        errorMessage: "Server is having error",
      },

      // CompaniesSection Component
      aiForHumanity: "AI FOR HUMANITY",
      discover: "DISCOVER",
      companies: {
        mila: {
          title: "Mila",
          description:
            "Our process is like a custom-built road trip for your business's journey into AI.\nWe've got five steps that take you from 'AI curious' to 'AI empowered', making sure we're hitting all the right spots along the way.",
        },
        ibm: {
          title: "IBM",
          description:
            "Our process is like a custom-built road trip for your business's journey into AI.\nWe've got five steps that take you from 'AI curious' to 'AI empowered', making sure we're hitting all the right spots along the way.",
        },
      },

      // YoutubeVideo Component
      learnMore: "LEARN MORE ABOUT AI FOR BUSINESS",
      introText1:
        "We provide our customers with various tools that are available for free on our website. Whether you prefer to read or to watch - we’ve got you!",
      introText2:
        "We offer comprehensive video workshops and guides, empowering learners to master AI concepts at their own pace. Dive deeper into the world of AI and innovations with our monthly curated issues of books, carefully selected to enrich your understanding and keep you abreast of the latest trends. Whether you're a novice or an expert, our resources provide invaluable insights and practical knowledge to fuel your journey in the exciting realm of artificial intelligence. Join us and unlock the potential of AI today.",
      videoDescriptions: {
        1: "Description for video 1",
        2: "Description for video 2",
        3: "Description for video 3",
      },
      viewPDF: "Books",
      viewAllVideos: "Video Guide",

      // Projects Component
      projects: {
        title: "GET YOUR CV DONE EASY WITH US",
        description:
          "Iter Innovandi stands ready to assist in tailoring your journey towards professional success - welcome Curriculum Vitai!",
        subDescription:
          "Instantly optimize your CV for your target job, enhancing your employment opportunities with a simple and stress-free process.",
        button: "COMING SOON",
      },

      // ServicesSection Component
      ourServices: "OUR SERVICES",
      servicesDescription:
        "A Few Services We Offer, But Are Not Limited To:",
      /*servicesDescription2:
        "We've got five steps that take you from 'AI curious' to 'AI empowered', making sure we're hitting all the right spots along the way.To start, we're giving you a free consultation and report. It's our gift to you, so you can see how much we can help your business, with no cost or commitment. Try us out and see how we make your business smarter, easier.",*/
      serviceTitles: {
        1: "Custom Chat-Bot Development",
        2: "AI-Powered Workflow Automation",
        3: "Predictive Analytics for Inventory Management",
        4: "Lead Generation AI Tools",
        5: "AI-Optimized Content Creation",
        6: "AI Enhanced Customer Relationship Management"
      },

      service1: {
        title: "Custom Chat-Bot Development",
        description: "Elevate customer service with 24/7 support through custom AI chatbots tailored to your business needs, enhancing engagement and efficiency."
      }, 
      service2: {
        title: "AI-Powered Workflow Automation",
        description: "Transform your AI operations with AI-driven automation for invoicing, email management, and HR tasks, boosting efficiency and cutting costs."
      }, 
      service3: {
        title: "Predictive Analytics for Inventory Management",
        description: "Optimize inventory with AI forecasting, reducing waste and ensuring demand is met efficienctly, streamlining stock management."
      }, 
      service4: {
        title: "Lead Generation AI Tools",
        description: "Drive sales with AI-powered lead generation, analyzing data to target and engage potential customers effectively, improving conversion rates."
      }, 
      service5: {
        title: "AI-Optimized Content Creation",
        description: "Update your CRM with AI for deeper customer insights, personalized marketing, and predictive purchasing behaviour analysis, enhancing loyalty."
      }, 
      service6: {
        title: "AI Enhanced Customer Relationship Management", 
        description: "Enhance your digital marketing with AI-driven content creation, optimizing for SEO and audience engagement to elevate your brand's online presence."
      }, 

      //SMEs
      intro: "TRANSFORM TOMORROW WITH AI TODAY",
      body: {
        run: "Make your business run smoother and faster.",
        work: "Less work, more growth for your business!",
      },
      learn: "LEARN MORE",

      business: {
        head: "EVERY BUSINESS IS AI READY",
        b1: "We sit with you, learn your business, and customize AI to optimize your processes.",
        b2: "Book Your Free Consultation Today!",
        b3: "BOOK NOW",
      },

      header: {
        about: "ABOUT",
        books: "BOOKS",
        service: "SERVICE",
        videos: "VIDEOS",
        projects: "PROJECTS",
        partners: "PARTNERS",
        book: "CONTACT US",
      },
      overLay: {
        nameRequired: 'Name is required',
        emailValid: 'Invalid email address',
        emailRequired: 'Email required',
        messageRequired: 'Message is required',
        addr: "CONTACT US",
        question: "What is this about?",
        name: "Your Name",
        email: "Your Email",
        ph: "Phone Number",
        message: "Enter Your Message",
        submit: "Submit",
        option1: "Consultation",
        option2: "Partnership",
        successMessage: "Thank you for contacting us. We will get back to you soon.",
        errorMessage: "There is an error, please try again",
      },
      office: "Head Office",
      partners: "OUR PARTNERS",
      intro2: 'WE VALUE OUR PARTNERS',

      // Book page section
      KNOWLEDGE_IS_POWER: "KNOWLEDGE IS POWER",
      infoText:
        "We provide our customers with various tools that are available for free on our website. Whether you prefer to read or to watch - we’ve got you!",
      descriptionText:
        "Consectetur. Lectus vestibulum ultricies aliquet ut cursus tincidunt. Velit vel dolor fermentum amet eu pellentesque tristique volutpat. Integer scelerisque senectus ac semper et aenean massa risus.",
      seeCollection: "SEE COLLECTION",
      EXPLORE_PREVIOUS_ISSUES: "EXPLORE PREVIOUS ISSUES",
      issueDescription:
        "This month's book explores cutting-edge methodologies in AI implementation, showcasing how artificial intelligence is reshaping various sectors.",
      WHATS_NEW_THIS_MONTH: "WHAT'S NEW THIS MONTH",
      newIssueIntro:
        "Innovations in AI Implementation. A quick overview of the new approaches for your ease.",
      newIssueDetail:
        "This month's book explores cutting-edge methodologies in AI implementation, showcasing how artificial intelligence is reshaping various sectors. From autonomous systems driven by reinforcement learning to personalized healthcare treatments, the book navigates through diverse domains where AI is making profound impacts. It delves into ethical considerations, emphasizing responsible AI development. Through compelling case studies and expert analysis, readers gain insights into how AI is redefining human-machine interactions. This book serves as a beacon for professionals seeking to stay abreast of the latest trends in AI implementation, inspiring future innovation and collaboration in this dynamic field.",
      download: "Download",

      upload: {
        "fileTooLarge": "File size cannot exceed {{maxSize}} MB",
        "maxFilesExceeded": "You can upload up to {{maxFiles}} files",
        "dragAndDrop": "Drag and drop files here, or click to select files",
        "uploadFailedFiles": "Failed to upload files:",
        "uploadFiles": "Upload Files",
        "close": "Close",
        "confirm": "Confirm",
        "cancel": "Cancel",
      },

      videoList: {
        recentlyUploaded: "Recently Uploaded",
        previousUploads: "Previous Uploaded",
        visitChannel: "VISIT OUR CHANNEL FOR MORE CONTENT",
        visitChannelURL: "https://www.youtube.com/iterinnovandi",
      },

      // partner page
      partner: {
        mila: "Mila is a community of more than 1,200 researchers specializing in machine learning and dedicated to scientific excellence and innovation.",
        ms: "Microsoft is a global leader in software, services, and solutions, empowering individuals and businesses with cutting-edge technology and cloud platforms.",
        aws: "AWS offers a comprehensive and widely adopted cloud platform, providing scalable and reliable cloud computing services to help businesses grow and innovate.",
        ibm: "IBM is a global technology company at the forefront of enterprise solutions, specializing in AI, cloud computing, and quantum technology to drive digital transformation.",
        trampoline: "Trampoline is a digital agency that specializes in creating engaging and impactful online experiences through innovative design and technology solutions.",
        obivee: "Obivee Agency is a creative agency focused on helping brands build strong, memorable identities through expert marketing strategies and creative storytelling.",
      }
    },
  },

  fr: {
    translation: {
      // General
      welcome: "Bienvenue",
      description:
        "Ceci est une application d'exemple pour le changement de langue.",
      switchLanguage: "Changer de langue",

      // LoginForm Component
      login:{      
        'Username is required': 'Le nom d\'utilisateur est requis',
        'Password is required': 'Le mot de passe est requis',
        'Enter your username': 'Entrez votre nom d\'utilisateur',
        'Enter your password': 'Entrez votre mot de passe',
        'Login successful!': 'Connexion réussie!',
        'Login failed. Please try again.': 'Échec de la connexion. Veuillez réessayer.',
        Username: 'Nom d\'utilisateur',
        Password: 'Mot de passe',
        "Logging in...": "Connexion...",
        Login: "Connexion",
        "Admin Login": "CONNEXION EN TANT QU'UTILISATEUR ADMIN",
      },

      //AboutUsIntroSection Component 
      aboutUsIntroSection: {
        title: 'À PROPOS DE \nNOUS',
        description1: "Nous sommes Iter Innovandi, et nous rendons \nl'IA facile pour toutes les entreprises.",
        description2: "Nos outils sont simples, rapides et prêts à aider votre entreprise \nà se développer et à s'améliorer rapidement. Avec nous, l'IA n'est \npas seulement intelligente, elle est facile et efficace pour vous !"
      },
      learnMoreButton: 'EN SAVOIR PLUS',

      //AboutUsProcessSection Component 
      aboutUsProcessSection: {
        title: "DE LA CURIOSITÉ \nÀ L'AUTONOMIE",
        description1: "Notre processus s'apparente à un voyage \nsur mesure pour votre entreprise vers l'IA.",
        description2: "Nous avons prévu cinq étapes pour vous faire passer de la curiosité à \nl'autonomie, en veillant à ce que nous touchions tous les points \nimportants en cours de route. Pour commencer, nous vous offrons \nune consultation et un rapport gratuits. C'est notre cadeau, pour que \nvous puissiez voir à quel point nous pouvons aider votre entreprise, \nsans frais ni engagement. Essayez-nous et voyez comment nous \nrendons votre entreprise plus intelligente et plus facile.",
      },

      //StepsCard Component
      step1: {
        title: "COMPRENDRE VOS BESOINS",
        description1: "Nous commençons par en apprendre davantage sur votre entreprise pour comprendre vos besoins et objectifs uniques.",
        description2: "Nous nous asseyons, nous discutons \nde la nature de votre \nentreprise et nous déterminons comment \nl'IA peut s'intégrer dans \nle tableau."
      },
      step2: {
        title: "SOLUTIONS D'IA PERSONNALISÉES",
        description1: "Nous créons des solutions d'IA personnalisées conçues pour s'adapter à votre entreprise et améliorer l'efficacité",
        description2: "Nous établissons un plan \nétape par étape, en vous \nmontrant comment nous allons \nmettre en œuvre les solutions \nd'IA dans votre entreprise."
      },
      step3: {
        title: "PLANIFICATION D'UNE MISE EN ŒUVRE",
        description1: "Nous élaborons un plan et un calendrier clairs pour intégrer en douceur l’IA dans vos opérations."
      },
      step4: {
        title: 'FORMATION ET SUPPORT',
        description1: "Nous fournissons des formations et un accompagnement pour garantir que votre équipe puisse utiliser efficacement les nouveaux outils d'IA.",
        description2: "Nous restons à vos côtés \npour nous assurer que votre \néquipe sache comment tirer \nle meilleur parti des \nnouveaux outils."
      },
      step5: {
        title: 'ENTRETIEN CONTINU',
        description1: "Nous offrons un support continu pour maintenir vos systèmes d'IA à jour et fonctionner correctement.",
        description2: "Nous sommes toujours à votre \ndisposition pour vérifier, régler \net dépanner, afin de nous \nassurer que tout \nfonctionne correctement."
      },
      //AboutUsChartSection Component 
      aboutUsChartSection: {
        title: "LE SAVIEZ-VOUS?",
      },

      // CaseStudies Component
      caseStudies: "ÉTUDES DE CAS",

      case_study1: {
        title: "AUGMENTATION DES FLUX DE TRÉSORERIE",
        description1: "UNE ÉTUDE DE McKINSEY & COMPANY",
        description2: "a révélé que l'adoption de l'IA pourrait entraîner une augmentation de 20 à 25 % des flux de trésorerie pour les petites entreprises.",
      },
      case_study2: {
        title: "SUCCÈS RAPPORTÉ",
        description1: "91 % DES PETITES ENTREPRISES UTILISANT L'IA",
        description2: "ont signalé un succès global, ce qui indique une forte corrélation entre l'utilisation de l'IA et le succès commercial.",
      },
      case_study3: {
        title: "AVANTAGES OBTENUS",
        description1: "UNE ÉTUDE DE DELOITTE A RÉVÉLÉ",
        description2: "83 % des premiers utilisateurs de l'IA ont déjà obtenu des avantages économiques substantiels (30 % ou plus).",
      },
      case_study4: {
        title: "AUGMENTATION DES VENTES GRÂCE À L'IA",
        description1: "ÉTUDE PAR SALESFORCE",
        description2: "ont constaté que les équipes de vente performantes sont 4,2 fois plus susceptibles que les moins performantes d'utiliser l'IA dans les processus de vente.",
      },
      case_study5: {
        title: "PLUS D'OPPORTUNITÉS",
        description1: "ÉTUDE PAR NARRATIVE SCIENCE",
        description2: "61 % des entreprises utilisent l'UA pour identifier des opportunités dans les données qui seraient autrement manquées.",
      },

      // Newsletter Component
      newsletter: {
        title: "RESTEZ AU COURANT DES DERNIERS OUTILS D'IA",
        description: "Abonnez-vous à notre newsletter mensuelle pour des informations et des mises à jour de pointe sur l'IA.",
        email : "Entrez votre e-mail",
        button: "S'ABONNER",
        successMessage: "Merci de vous être abonné à la newsletter",
        userExistMessage: "Cet email est déjà abonné à la newsletter",
        errorMessage: "Le serveur rencontre une erreur",
      },

      // CompaniesSection Component
      aiForHumanity: "L'IA POUR L'HUMANITÉ",
      discover: "DÉCOUVRIR",
      companies: {
        mila: {
          title: "Mila",
          description:
            "Notre processus est comme un voyage personnalisé pour l'aventure de votre entreprise dans l'IA.\nNous avons cinq étapes qui vous emmènent de 'curieux de l'IA' à 'maîtriser l'IA', en nous assurant de visiter tous les bons endroits en cours de route.",
        },
        ibm: {
          title: "IBM",
          description:
            "Notre processus est comme un voyage personnalisé pour l'aventure de votre entreprise dans l'IA.\nNous avons cinq étapes qui vous emmènent de 'curieux de l'IA' à 'maîtriser l'IA', en nous assurant de visiter tous les bons endroits en cours de route.",
        },
      },

      // YoutubeVideo Component
      learnMore: "EN SAVOIR PLUS SUR L'IA POUR LES ENTREPRISES",
      introText1:
        "Nous fournissons à nos clients divers outils disponibles gratuitement sur notre site Web. Que vous préfériez lire ou regarder - nous vous avons couvert!",
      introText2:
        "Nous proposons des ateliers vidéo complets et des guides, permettant aux apprenants de maîtriser les concepts de l'IA à leur propre rythme. Plongez plus profondément dans le monde de l'IA et des innovations avec nos numéros mensuels de livres, soigneusement sélectionnés pour enrichir votre compréhension et vous tenir au courant des dernières tendances. Que vous soyez novice ou expert, nos ressources fournissent des informations inestimables et des connaissances pratiques pour alimenter votre voyage dans le domaine passionnant de l'intelligence artificielle. Rejoignez-nous et libérez le potentiel de l'IA dès aujourd'hui.",
      videoDescriptions: {
        1: "Description de la vidéo 1",
        2: "Description de la vidéo 2",
        3: "Description de la vidéo 3",
      },
      viewPDF: "Livres",
      viewAllVideos: "Guide Vidéo",

      // Projects Component
      projects: {
        title: "RÉALISEZ VOTRE CV FACILEMENT AVEC NOUS",
        description:
          "Iter Innovandi est prêt à vous aider à personnaliser votre parcours vers le succès professionnel - bienvenue à Curriculum Vitai!",
        subDescription:
          "Optimisez immédiatement votre CV pour l'emploi ciblé, améliorant vos opportunités d'emploi avec un processus simple et sans stress.",
        button: "À VENIR",
      },

      // ServicesSection Component
      ourServices: "NOS SERVICES",
      serviceTitles: {
        1: "Développement de Chat-Bot sur Mesure",
        2: "Automatisation des Flux de Travail Propulsée par l'IA",
        3: "Analyse Prédictive pour la Gestion des Stocks",
        4: "Outils d'IA pour la Génération de Leads",
        5: "Traitement du Langage Naturel pour l'Analyse de Contenu",
        6: "Gestion de la Relation Client Améliorée par l'IA",
        7: "Modèles d'IA Personnalisés pour l'Analyse de Marché",
        8: "Moteurs de Personnalisation Propulsés par l'IA",
        9: "Systèmes d'Assurance Qualité Automatisés",
        10: "Création de Contenu Optimisée par l'IA",
      },
      serviceDescription: "Description de {{title}}",
      servicesDescription:
        "Quelques services que nous offrons, mais sans s'y limiter :",
      servicesDescription2:
        "Nous avons cinq étapes qui vous emmènent de 'curieux de l'IA' à 'maîtriser l'IA', en nous assurant de visiter tous les bons endroits en cours de route. Pour commencer, nous vous offrons une consultation et un rapport gratuits. C'est notre cadeau pour vous, afin que vous puissiez voir combien nous pouvons aider votre entreprise, sans frais ni engagement. Essayez-nous et voyez comment nous rendons votre entreprise plus intelligente, plus facile.",

      // SMEs
      intro: "TRANSFORMEZ DEMAIN AVEC L'IA AUJOURD'HUI",
      body: {
        run: "Faites fonctionner votre entreprise plus facilement et plus rapidement.",
        work: "Moins de travail, plus de croissance pour votre entreprise!",
      },
      learn: "EN SAVOIR PLUS",

      business: {
        head: "CHAQUE ENTREPRISE EST PRÊTE POUR L'IA",
        b1: "Nous nous asseyons avec vous, apprenons votre métier et personnalisons l'IA pour optimiser vos processus.",
        b2: "Réservez Votre Consultation Gratuite Dès Aujourd'hui!",
        b3: "RÉSERVER MAINTENANT",
      },

      header: {
        about: "ÀPROPOS",
        books: "LIVRES",
        service: "SERVICE",
        videos: "VIDÉOS",
        projects: "PROJETS",
        partners: "PARTENAIRES",
        book: "CONTACTEZ-NOUS",
      },
      overLay: {
        nameRequired: 'Le nom est obligatoire',
        emailValid: 'Adresse e-mail non valide',
        emailRequired: 'Email requis',
        messageRequired: 'Le message est obligatoire',
        addr: "CONTACTEZ-NOUS",
        question: "De quoi s'agit-il?",
        name: "Votre nom",
        email: "Votre e-mail",
        ph: "Numéro de téléphone",
        message: "Entrez votre message",
        submit: "Soumettre",
        option1: "Consultation",
        option2: "Partenariat",
        successMessage: "Merci de nous avoir contactés. Nous vous répondrons dans les plus brefs délais.",
        errorMessage: "Il y a une erreur, veuillez réessayer",
      },
      office: "Siège Social",
      partners: "NOS PARTENAIRES",
      
      // Book page section
      KNOWLEDGE_IS_POWER: "LA CONNAISSANCE EST LE POUVOIR",
      infoText:
        "Nous fournissons à nos clients divers outils disponibles gratuitement sur notre site Web. Que vous préfériez lire ou regarder - nous vous avons couvert!",
      descriptionText:
        "Consectetur. Lectus vestibulum ultricies aliquet ut cursus tincidunt. Velit vel dolor fermentum amet eu pellentesque tristique volutpat. Integer scelerisque senectus ac semper et aenean massa risus.",
      seeCollection: "VOIR LA COLLECTION",
      EXPLORE_PREVIOUS_ISSUES: "EXPLORER LES NUMÉROS PRÉCÉDENTS",
      issueDescription:
        "Le livre de ce mois-ci explore des méthodologies de pointe dans la mise en œuvre de l'IA, montrant comment l'intelligence artificielle transforme divers secteurs.",
      WHATS_NEW_THIS_MONTH: "QUOI DE NEUF CE MOIS",
      newIssueIntro:
        "Innovations dans la mise en œuvre de l'IA. Un aperçu rapide des nouvelles approches pour votre commodité.",
      newIssueDetail:
        "Le livre de ce mois-ci explore des méthodologies de pointe dans la mise en œuvre de l'IA, montrant comment l'intelligence artificielle transforme divers secteurs. Des systèmes autonomes alimentés par l'apprentissage par renforcement aux traitements de santé personnalisés, le livre navigue à travers divers domaines où l'IA a des impacts profonds. Il se penche sur les considérations éthiques, en mettant l'accent sur le développement responsable de l'IA. À travers des études de cas convaincantes et une analyse experte, les lecteurs acquièrent des insights sur la manière dont l'IA redéfinit les interactions homme-machine. Ce livre sert de phare pour les professionnels cherchant à se tenir au courant des dernières tendances en matière de mise en œuvre de l'IA, inspirant une future innovation et collaboration dans ce domaine dynamique.",
      download: "Télécharger",

      videoList: {
        pageTitle: "APPRENDRE L'IA EST FACILE",
        pageSubtitle:
          "Nous offrons à nos clients divers outils disponibles gratuitement sur notre site Web.",
        mainVideoDescription:
          "Consectetur. Lectus vestibulum ultricies aliquet ut cursus tincidunt. Velit vel dolor fermentum amet eu pellentesque tristique volutpat. Integer scelerisque senectus ac semper et aenean massa risus.",
        recentlyUploaded: "RÉCEMMENT TÉLÉCHARGÉ",
        recentUploadMainDescription:
          "Innovations dans la mise en œuvre de l'IA. Un aperçu rapide des nouvelles approches pour votre facilité.",
        recentUploadDescription:
          "La vidéo de ce mois explore comment l'IA transforme divers secteurs, des systèmes autonomes aux soins de santé personnalisés. Il plonge dans les considérations éthiques et présente des études de cas convaincantes. Les téléspectateurs acquièrent des informations sur l'impact de l'IA sur les interactions homme-machine, inspirant l'innovation future dans ce domaine dynamique.",
        previousUploads: "TÉLÉCHARGEMENTS PRÉCÉDENTS",
        visitChannel: "VISITEZ NOTRE CHAÎNE POUR ENCORE PLUS DE CONTENU",
        visitChannelURL: "https://www.youtube.com/iterinnovandi",
      },

      upload: {
        "fileTooLarge": "La taille du fichier ne peut pas dépasser {{maxSize}} Mo",
        "maxFilesExceeded": "Vous pouvez télécharger jusqu'à {{maxFiles}} fichiers",
        "dragAndDrop": "Glissez-déposez les fichiers ici, ou cliquez pour sélectionner les fichiers",
        "uploadFailedFiles": "Échec du téléchargement des fichiers :",
        "uploadFiles": "Télécharger des fichiers",
        "close": "Fermer",
        "confirm": "Confirmer",
        "cancel": "Annuler",
      },

      // partner page
      partner: {
        mila: "Mila est une communauté de plus de 1 200 chercheurs spécialisés en apprentissage automatique et dédiés à l'excellence scientifique et à l'innovation.",
        ms: "Microsoft est un leader mondial en matière de logiciels, de services et de solutions, offrant aux particuliers et aux entreprises des technologies de pointe et des plateformes cloud.",
        aws: "AWS propose une plateforme cloud complète et largement adoptée, fournissant des services de cloud computing évolutifs et fiables pour aider les entreprises à se développer et à innover.",
        ibm: "IBM est une entreprise technologique mondiale à la pointe des solutions d'entreprise, spécialisée dans l'IA, le cloud computing et la technologie quantique pour stimuler la transformation numérique.",
        trampoline: "Trampoline est une agence numérique spécialisée dans la création d'expériences en ligne engageantes et percutantes grâce à des solutions de conception et de technologie innovantes.",
        obivee: "Obivee Agency est une agence créative qui aide les marques à créer des identités fortes et mémorables grâce à des stratégies marketing expertes et à une narration créative.",
      }
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
