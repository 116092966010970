/**
 * @fileOverview This file contains the error code mapping for the application.
 * @author Lisi Cao
 * @version 1.1.0
 * @company Iter Innovandi.
 */
export const errorCodeMapping = {
    '1001': 'Invalid input data',
    '1002': 'Video not found',
    '1003': 'Permission denied',

    '4000': 'Invalid email or password', 
    '4001': 'User already exists', 
    '4002': 'Unauthorized access', 
    '4003': 'Token expired', 
    '4004': 'Invalid token', 
    '4005': 'Failed to send password reset email', 

    '4100': 'User not found', 
    '4101': 'Failed to update user profile', 
    '4102': 'Failed to delete user', 
    '4103': 'Failed to retrieve user', 

    '4200': 'Database connection error', 
    '4201': 'Query execution failed', 
    '4202': 'Data not found', 
    '4203': 'Failed to update data', 
    '4204': 'Failed to delete data', 

    '4300': 'Invalid input', 
    '4301': 'Missing required parameter', 
    '4302': 'Operation failed',
    '4303': 'Unknown error', 
    '4304': 'Service unavailable', 
    '4305': 'Network error', 
    '4306': 'Unexpected error', 

    '2000': 'Success',  

    // Other business error codes
  };
  