import React from 'react'
import Layout from '../components/layout/Layout';
import PartnersValue from '../components/partners/PartnersValue';

const PartnersPage = () => {
  return (
    <Layout>
        <PartnersValue/>
    </Layout>
  )
}

export default PartnersPage